











































































import { Vue, Component, Prop } from "vue-property-decorator";
import Shelfs from './Shelfs.vue'
import Drawers from './Drawers.vue'

@Component({
  components: {Shelfs, Drawers}
})
export default class FreezerVisualizerModal extends Vue {
  @Prop() readonly onClose: Function | undefined;
  @Prop() readonly shelf: number | undefined;
  @Prop() readonly drawer: string | undefined;
  @Prop() readonly row: number | undefined;
  @Prop() readonly box: number | undefined;
}

@Component
export class FreezerVisualizer extends Vue{
    isFreezerVisualizationOpened = false
    protected openFreezerVisualization(){
      this.isFreezerVisualizationOpened = true
    }
    protected closeFreezerVisualization(){
      this.isFreezerVisualizationOpened = false
    }
}

