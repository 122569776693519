import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import AddBiospecimenType from "../pages/AddBiospecimenType/AddBiospecimenType.vue";
import AddDisease from "../pages/AddDisease/AddDisease.vue";
import AddLocation from "../pages/AddLocation/AddLocation.vue";
import AddLocationOnly from "../pages/AddLocation/AddLocationOnly.vue";
import AddProject from "../pages/AddProject/AddProject.vue";
import AddProjectOnly from "../pages/AddProject/AddProjectOnly.vue";
import AddStaff from "../pages/AddStaff/AddStaff.vue";
import AddUserAccount from "../pages/AddUserAccount/AddUserAccount.vue";
import AssignTube from "../pages/AssignTube/AssignTube.vue";
import AssignTubesFromBasket from "../pages/AssignTubesFromBasket/AssignTubesFromBasket.vue";
import Authenticate from "../pages/Authenticate.vue";
import BNs from "../pages/BNs/BNs.vue";
import BiospecimenTypes from "../pages/BiospecimenTypes/BiospecimenTypes.vue";
import Box from "../pages/Box/Box.vue";
import Dashboard2 from "../pages/Dashboard/Dashboard2.vue";
import Diseases from "../pages/Diseases/Diseases.vue";
import GenerateBN from "../pages/GenerateBN/GenerateBN.vue";
import Locations from "../pages/Locations/Locations.vue";
import MoveTube from "../pages/MoveTube/MoveTube.vue";
import MoveTubesFromBasket from "../pages/MoveTubesFromBasket/MoveTubesFromBasket.vue";
import NotFound404 from "../pages/NotFound404.vue";
import PrintBN from "../pages/PrintBN/PrintBN.vue";
import PrintBNBasket from "../pages/PrintBN/PrintBNBasket.vue";
import PrintLocationLabel from "../pages/PrintLocationLabel/PrintLocationLabel.vue";
import Projects from "../pages/Projects/Projects.vue";
import RolesAndPermissions from "../pages/RolesAndPermissions/RolesAndPermissions.vue";
import SearchBNResult from "../pages/SearchLocation/SearchBNResult.vue";
import SearchLocation from "../pages/SearchLocation/SearchLocation.vue";
import Staffs from "../pages/Staffs/Staffs.vue";
import TubeActionLogs from "../pages/TubeActionLogs/TubeActionLogs.vue";
import UserAccounts from "../pages/UserAccounts/UserAccounts.vue";
import AuthenticateStore from "../store/Authenticate";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "signIn",
    component: Authenticate,
  },
  {
    path: "/addProject",
    name: "addProject",
    component: AddProject,
  },
  {
    path: "/generateBN/:projectId",
    name: "generateBN",
    component: GenerateBN,
  },
  {
    path: "/addLocation/:projectId/:bn",
    name: "addLocation",
    component: AddLocation,
  },
  {
    path:
      "/addLocation/:projectId/:tubeNoLowerBound/:tubeNoUpperBound/:biobankNosStr",
    name: "addLocationMultipleTubes",
    component: AddLocation,
  },
  {
    path: "/box/:locationId",
    name: "box",
    component: Box,
  },
  {
    path: "/box/:locationId/:newBN/:row/:column",
    name: "boxNewBN",
    component: Box,
  },
  {
    path: "/box/:locationId/:searchingBN",
    name: "boxSearchBN",
    component: Box,
  },
  {
    path:
      "/boxHighlightMultiple/:locationId/:locationXs/:locationYs/:unassignedBNs",
    name: "boxNewMultipleBNs",
    component: Box,
  },
  {
    path: "/searchLocation",
    name: "searchLocation",
    component: SearchLocation,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard2,
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects,
    props: (route) => ({ query: route.query.newProject }),
  },
  {
    path: "/projects/:updatedProject",
    name: "projectsUpdated",
    component: Projects,
  },
  {
    path: "/projectsDeleted",
    name: "projectsDeleted",
    component: Projects,
  },
  {
    path: "/bns",
    name: "bns",
    component: BNs,
  },
  {
    path: "/addProjectOnly",
    name: "addProjectOnly",
    component: AddProjectOnly,
  },
  {
    path: "/addLocationOnly",
    name: "addLocationOnly",
    component: AddLocationOnly,
  },
  {
    path: "/locations",
    name: "locations",
    component: Locations,
  },
  {
    path: "/locations/:updatedLocation",
    name: "locationsUpdated",
    component: Locations,
  },
  {
    path: "/locationsDeleted",
    name: "locationsDeleted",
    component: Locations,
  },
  {
    path: "/addStaff",
    name: "addStaff",
    component: AddStaff,
  },
  {
    path: "/staffs",
    name: "staffs",
    component: Staffs,
  },
  {
    path: "/staffs/updated",
    name: "staffsUpdated",
    component: Staffs,
  },
  {
    path: "/staffsDeleted",
    name: "staffsDeleted",
    component: Staffs,
  },
  {
    path: "/addDisease",
    name: "addDisease",
    component: AddDisease,
  },
  {
    path: "/diseases",
    name: "diseases",
    component: Diseases,
  },
  {
    path: "/diseases/:updatedDisease",
    name: "diseasesUpdated",
    component: Diseases,
  },
  {
    path: "/diseasesDeleted",
    name: "diseasesDeleted",
    component: Diseases,
  },
  {
    path: "/assignTube/:biobankNo",
    name: "assignTube",
    component: AssignTube,
  },
  {
    path: "/moveTube/:oldLocationId/:biobankNo",
    name: "moveTube",
    component: MoveTube,
  },
  {
    path: "/searchBNResult",
    name: "searchBNResult",
    component: SearchBNResult,
  },
  {
    path: "/searchBNResult/:updatedBN",
    name: "searchBNResultUpdated",
    component: SearchBNResult,
  },
  {
    path: "/searchBNResultDeleted",
    name: "searchBNResultDeleted",
    component: SearchBNResult,
  },
  {
    path: "/addBiospecimenType",
    name: "addBiospecimenType",
    component: AddBiospecimenType,
  },
  {
    path: "/biospecimenTypes",
    name: "biospecimenTypes",
    component: BiospecimenTypes,
  },
  {
    path: "/biospecimenTypes/:updatedBiospecimenCode",
    name: "biospecimenTypesUpdated",
    component: BiospecimenTypes,
  },
  {
    path: "/biospecimenTypesDeleted",
    name: "biospecimenTypesDeleted",
    component: BiospecimenTypes,
  },
  {
    path: "/tubeActionLogs",
    name: "tubeActionLogs",
    component: TubeActionLogs,
  },
  {
    path: "/addUserAccount",
    name: "addUserAccount",
    component: AddUserAccount,
  },
  {
    path: "*",
    name: "404",
    component: NotFound404,
  },
  {
    path: "/userAccounts",
    name: "userAccounts",
    component: UserAccounts,
  },
  {
    path: "/userAccounts/:updatedUsername",
    name: "userAccountsUpdated",
    component: UserAccounts,
  },
  {
    path: "/userAccountsDeleted",
    name: "userAccountsDeleted",
    component: UserAccounts,
  },
  {
    path: "/printBN",
    name: "printBN",
    component: PrintBN,
  },
  {
    path: "/printBNBasket",
    name: "printBNBasket",
    component: PrintBNBasket,
  },
  {
    path: "/assignTubes",
    name: "assignTubes",
    component: AssignTubesFromBasket,
  },
  {
    path: "/rolesAndPermissions",
    name: "rolesAndPermissions",
    component: RolesAndPermissions,
  },
  {
    path: "/moveTubes",
    name: "moveTubes",
    component: MoveTubesFromBasket,
  },
  {
    path: "/printLocationLabel",
    name: "printLocationLabel",
    component: PrintLocationLabel,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

function blockRouteByPms(to: Route, next: NavigationGuardNext<Vue>) {
  if (!AuthenticateStore.signedInPms) {
    next({ name: "signIn" });
    return;
  }
  //block all route accessing user account management
  if (!AuthenticateStore.signedInPms.userAndRole) {
    if (
      to.name === "userAccounts" ||
      to.name === "rolesAndPermissions" ||
      to.name === "addUserAccount"
    )
      next({ name: "dashboard" });
  }
  //block all route accessing add data pages
  if (!AuthenticateStore.signedInPms.add) {
    if (
      to.name === "addProject" ||
      to.name === "generateBN" ||
      to.name === "addLocation" ||
      to.name === "addProjectOnly" ||
      to.name === "addLocationOnly" ||
      to.name === "addStaff" ||
      to.name === "addDisease" ||
      to.name === "addBiospecimenType"
    )
      next({ name: "dashboard" });
  }
  //block all route accessing move tube pages
  if (!AuthenticateStore.signedInPms.move) {
    if (to.name === "moveTube" || to.name === "moveTubes")
      next({ name: "dashboard" });
  }
}

router.beforeEach(async (to, from, next) => {
  //if route to anything but signIn page and is not authened yet
  if (to.name !== "signIn" && !AuthenticateStore.signedIn) {
    //load token from storage
    let token: string | null = null;
    token = localStorage.getItem("token");
    //check if token is expired (or forged)
    const result = await AuthenticateStore.reSignIn(token);
    if (result) {
      blockRouteByPms(to, next);
      next(); //pass authen
    } else next({ name: "signIn" }); //not pass authen
  } else if (to.name === "signIn") {
    next();
  } else {
    blockRouteByPms(to, next);
    next();
  }
});

export default router;
