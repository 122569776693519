






































import { Vue, Component, Prop } from "vue-property-decorator";
import { TubeActionLog } from "../../store/FetchTubeActionLogs";
import { format } from "date-fns";

@Component
export default class TubeActionLogsTableSkeleton extends Vue {
  @Prop() readonly tubeActionLogs: Array<TubeActionLog> | undefined;
  @Prop() readonly defaultSort: Array<string> | undefined
  @Prop() readonly onSort: Function | undefined

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }
}
