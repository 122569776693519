import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

export type Action = 'add' | 'remove' | 'edit' | 'move' | 'take' | 'userAndRole'

export type Permissions = {
  add: boolean;
  remove: boolean;
  edit: boolean;
  move: boolean;
  take: boolean;
  userAndRole: boolean;
}

export type Role = {
  _id?: string;
  roleName: string;
  permissions: Permissions;
  isRoot: boolean;
}

@Module({ name: 'UserRoleModule', store, dynamic: true, namespaced: true })
class UserRoleStore extends VuexModule {

  roles: Role[] = []
  loading = false

  @Mutation
  FETCH_ROLES(payload: Role[]){
    this.roles = payload
  }

  @Mutation
  SET_LOADING(v: boolean) {
    this.loading = v
  }

  @Action
  async fetchRoles(){
    this.SET_LOADING(true)
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_ROLE_LIST
    return axios.get(url, { headers: {token: loadToken()}})
      .then(resp => {
        this.FETCH_ROLES(resp.data.roles)
        this.SET_LOADING(false)
        return resp.data.roles
      })
      .catch(err => {
        this.SET_LOADING(false)
        console.log(err)
      })
  }

  @Action
  async postAddRole(role: Role) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_ROLE_ADD
    return axios.post(url, role,
      { headers: {token: loadToken()}}
    ).then(resp => {
      return resp.data
    }).catch(err => {
      if (err?.response)
        return err.response.data
      else
        return {
          status: 'failure',
          message: 'Failed to create role. Please try again later.'
        }
    })
  }

  @Action
  async postEditRole(role: Required<Role>) {
    console.log('pass')
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_ROLE_EDIT
    return axios.post(url, role,
      { headers: {token: loadToken()}}
    ).then(resp => {
      return resp.data
    }).catch(err => {
      if (err?.response)
        return err.response.data
      else
        return {
          status: 'failure',
          message: 'Failed to edit role. Please try again later.'
        }
    })
  }
}

export default getModule(UserRoleStore)