




















import { Vue, Component } from "vue-property-decorator";
import Navbar from "./components/Navbar.vue";

@Component({
  components: { Navbar },
})
export default class App extends Vue {
  mounted() {
    console.log("Biobank web app version 0.2.4.3");
  }

  get shouldRenderNavbar() {
    return !(
      ["/printLocationLabel"].includes(this.$route.fullPath) || this.isLoginUrl
    );
  }

  get isLoginUrl() {
    return this.$route.fullPath === "/";
  }

  get footerText() {
    const date = new Date();
    const year = date.getFullYear();
    return `© ${year} - SRU-IMS by Department of Computer Engineering CMU`;
  }
}
