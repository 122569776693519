




















import {Vue, Component, Prop} from 'vue-property-decorator'

@Component
export default class Drawers extends Vue {
  @Prop() readonly activate: number | undefined
}
