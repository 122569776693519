




































































import sleep from "sleep-promise";
import { Vue, Component, Prop } from "vue-property-decorator";
import { parse, format } from "date-fns";
import UpdateBNStore from "../../store/UpdateBN";
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class EditBNModal extends Vue {
  @Prop() readonly onClose: Function | undefined;
  @Prop() readonly biobankNo: string | undefined;
  @Prop() readonly admitDateStr: string | undefined;
  @Prop() readonly migrate: boolean | undefined;

  updateResult = "";
  inputMigrate = false;
  currentDate: Date = new Date();
  inputDateFailed = false;
  currentTime: Date = new Date();
  inputTimeFailed = false;

  //prevent user select only month or year
  changedMonthOrYear = false;
  lastSetDayTime = new Date();

  authenedUserHasPms = authenedUserHasPms

  mounted() {
    this.inputMigrate = this.migrate || false;
    this.currentDate = new Date(this.admitDateStr || "");
    this.currentTime = new Date(this.admitDateStr || "");
  }

  customDateParser(dateStr: string) {
    const dateOnly = parse(dateStr, "yyyy-MM-dd", this.currentDate);
    this.currentDate = new Date(
      dateOnly.getFullYear(),
      dateOnly.getMonth(),
      dateOnly.getDate()
    );
    return this.currentDate;
  }

  dateFormatter(date: Date) {
    return format(date, "yyyy-MM-dd");
  }

  get currentDateTime(): Date {
    const output = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate(),
      this.currentTime.getHours(),
      this.currentTime.getMinutes(),
      this.currentTime.getSeconds()
    );
    return output;
  }

  changeMonthOrYear() {
    if (Date.now() - this.lastSetDayTime.valueOf() > 500) {
      this.changedMonthOrYear = true;
    }
  }

  changeDay() {
    this.changedMonthOrYear = false;
    this.lastSetDayTime = new Date();
  }

  async updateBN() {
    this.updateResult = "";
    this.inputDateFailed = false;
    this.inputTimeFailed = false;
    let passValidate = true;
    await sleep(250);

    //validate
    if (!this.currentDate || isNaN(this.currentDate.getDate())) {
      this.inputDateFailed = true;
      passValidate = false;
    }
    if (!this.currentTime || isNaN(this.currentTime.getDate())) {
      this.inputTimeFailed = true;
      passValidate = false;
    }
    if (!passValidate) {
      this.updateResult = "Please fill all required data correctly";
      return;
    }

    //warn to check date picker
    if (this.changedMonthOrYear) {
      this.$buefy.dialog.alert({
        title: "Selecting Admit Date",
        message:
          "You set <b>month</b> or <b>year</b> but did not set <b>day</b>. Please recheck admit date again before submitting",
        type: "is-warning",
        hasIcon: true,
        icon: "exclamation-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
      this.changedMonthOrYear = false;
      return;
    }

    const result = await UpdateBNStore.updateBN({
      biobankNo: this.biobankNo || "",
      admitDate: this.currentDateTime,
      migrate: this.inputMigrate,
    });

    if (result.status === "failure") this.updateResult = result.message;
    else if (result.status === "success") {
      this.$emit('close')
      this.$router
            .push({
              name: "searchBNResultUpdated",
              params: { updatedBN: this.biobankNo || "" },
              query: {
                ...this.$route.query,
              },
            })
            .catch(() => this.$router.go(0));
    }
  }

  openConfirmDelete() {
    this.$buefy.dialog.confirm({
      title: "Deleting Sample",
      message:
        "Are you sure you want to <b>delete</b> sample? This action cannot be undone.",
      confirmText: "Delete",
      type: "is-danger",
      hasIcon: true,
      onConfirm: () => this.deleteBN(),
    });
  }

  async deleteBN() {
    this.updateResult = "";
    sleep(250);
    const result = await UpdateBNStore.deleteBN({
      biobankNo: this.biobankNo || "",
    });

    if (result.status === "success") {
      this.$emit('close')
      this.$router
        .push({
          name: "searchBNResultDeleted",
          query: this.$route.query,
        })
        .catch(() => this.$router.go(0));
    } else if (result.status === "failure") {
      this.updateResult = result.message;
    }
  }
}
