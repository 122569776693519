












































































































import { Vue, Component, Prop } from "vue-property-decorator";
import FetchBNStore from "../store/FetchBN";
import TakeTubeStore from "../store/TakeTube";
import { format } from "date-fns";
import {authenedUserHasPms} from '../utils/Role'

@Component
export default class TubeModal extends Vue {
  @Prop() readonly biobankNo: string | undefined;
  @Prop() readonly onClose: Function | undefined;

  authenedUserHasPms = authenedUserHasPms

  async mounted() {
    FetchBNStore.RESET();
    const result = await FetchBNStore.fetchBN(this.biobankNo || "");
    if (result.status === "failure") {
      this.$buefy.dialog.alert({
        title: "Error",
        message: result.message,
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
      });
      if (this.onClose) this.onClose();
    }
  }

  uiAssignTube() {
    this.$emit('close')
    this.$router.push({
      name: "assignTube",
      params: { biobankNo: this.biobankNo || "" },
    });
  }

  uiConfirmTakeTube() {
    if (!this.bn || !this.bn.locationId) return;
    const locationId = this.bn.locationId;

    this.$buefy.dialog.confirm({
      title: "Taking Sample",
      message: "Are you sure you want to take this sample from the box?",
      confirmText: "Take sample",
      type: "is-primary",
      hasIcon: true,
      onConfirm: async () => {
        const result = await TakeTubeStore.postTakeTube({
          locationId: locationId || "",
          biobankNo: this.biobankNo || "",
        });
        if (result.status === "success") {
          this.$emit('close')
          this.$router.push({
            name: "box",
            params: { locationId },
            query: { takenBiobankNo: this.biobankNo },
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Error",
            message: result.message,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
          });
        }
      },
    });
  }

  uiNavigateToMoveTube() {
    if (this.bn && this.bn.locationId) {
      this.$emit('close')
      this.$router.push({
        name: "moveTube",
        params: {
          oldLocationId: this.bn.locationId || "",
          biobankNo: this.biobankNo || "",
        },
      });
    }
  }

  uiNavigateToBox() {
    if (!this.bn || !this.bn.locationId) return;
    const locationId = this.bn.locationId;
    this.$emit('close')
    this.$router.push({
      name: "boxSearchBN",
      params: { locationId, searchingBN: this.biobankNo || "" },
    });
  }

  get bn() {
    return FetchBNStore.fetchedBN;
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }
}
