import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

type AVisualizeLocation = {
  boxId: string;
}

type Box = {
  boxId: string;
  label: string;
  containBnsN: number;
  boxSize: number;
  block: number;
  drawer: string;
  rackRow: number;
  rackColumn: number;
}

type MVisualizeLocation = {
  freezerNo: string;
  boxId: string;
  block: number;
  boxs: Box[];
}

@Module({ name: 'VisualizeLocationModule', store, dynamic: true, namespaced: true })
class VisualizeLocationStore extends VuexModule {

  freezerNo: string | null = null
  boxId: string | null = null
  block: number | null = null
  boxs: Box[] | null = null


  @Mutation
  VISUALIZE_LOCATION(payload: MVisualizeLocation){
    this.freezerNo = payload.freezerNo
    this.boxId = payload.boxId
    this.boxs = payload.boxs
    this.block = payload.block
  }

  @Action
  async visualizeLocation(payload: AVisualizeLocation){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_VISUALIZE
    return axios.get(
      `${url}/${payload.boxId}`, { headers: {token: loadToken()}}
    )
      .then(resp => {
        this.VISUALIZE_LOCATION(resp.data)
        return resp.data
      })
  }
}

export default getModule(VisualizeLocationStore)