
































































































































































































import { Vue, Component } from "vue-property-decorator";
import AddProjectStore from "../../store/AddProject";
import AddBiospecimenStore from "../../store/AddBiospecimen";
import SearchLocationStore from "../../store/SearchLocation";
import { format } from "date-fns";


//todo unset date button
@Component
export default class SearchBNLocation extends Vue {
  foundBNLocation: boolean | null = null;
  searchLocationMessage = "";
  preGenDataIsLoaded = false

  async mounted() {
    AddProjectStore.RESET();
    await AddProjectStore.fetchProjects({sortBy: 'id', orderBy: 1});
    await AddProjectStore.fetchPreGenData();

    AddBiospecimenStore.RESET();
    await AddBiospecimenStore.fetchBiospecimenTypes();
    this.preGenDataIsLoaded = true
  }

  onProjectIdSelectChange(v: string){
    if(v === "Deselect")
      this.inputProjectId = null
  }

  onDiseaseIdSelectChange(v: string){
    if(v === "Deselect")
      this.inputDiseaseId = null
  }

  onBiospecimenTypeSelectChange(v: string){
    if(v === "Deselect")
      this.inputSampleCode = null
  }

  onTubeStatusSelectChange(v: string){
    if(v === "Deselect")
      this.inputTubeStatus = null
  }

  onMigrateSelectChange(v: string){
    if(v === 'Deselect')
      this.inputMigrate = null
  }

  get inputBiobankNo(){return SearchLocationStore.inputBiobankNo}
  set inputBiobankNo(v: string){ SearchLocationStore.INPUT_BIOBANK_NO(v)}
  get inputProjectId(){return SearchLocationStore.inputProjectId}
  set inputProjectId(v: string | null){ SearchLocationStore.INPUT_PROJECT_ID(v)}
  get inputDiseaseId(){return SearchLocationStore.inputDiseaseId}
  set inputDiseaseId(v: string | null){ SearchLocationStore.INPUT_DISEASE_ID(v)}
  get inputPatientBN(){return SearchLocationStore.inputPatientBN}
  set inputPatientBN(v: string){ SearchLocationStore.INPUT_PATIENT_BN(v)}
  get inputVisitTime(){return SearchLocationStore.inputVisitTime}
  set inputVisitTime(v: string){ SearchLocationStore.INPUT_VISIT_TIME(v)}
  get inputTubeNo(){return SearchLocationStore.inputTubeNo}
  set inputTubeNo(v: string){ SearchLocationStore.INPUT_TUBE_NO(v)}
  get inputSampleCode(){ return SearchLocationStore.inputSampleCode}
  set inputSampleCode(v: string | null){ SearchLocationStore.INPUT_SAMPLE_CODE(v)}
  get inputTubeStatus(){ return SearchLocationStore.inputTubeStatus}
  set inputTubeStatus(v: string | null){ SearchLocationStore.INPUT_TUBE_STATUS(v)}
  get inputMigrate(){ return SearchLocationStore.inputMigrate}
  set inputMigrate(v: string | null){ SearchLocationStore.INPUT_MIGRATE(v)}

  get inputAdmitDateStart(){ return SearchLocationStore.inputAdmitDateStart}
  set inputAdmitDateStart(v: Date | null){ SearchLocationStore.INPUT_ADMIT_DATE_START(v)}
  get inputAdmitDateEnd(){ return SearchLocationStore.inputAdmitDateEnd}
  set inputAdmitDateEnd(v: Date | null){ SearchLocationStore.INPUT_ADMIT_DATE_END(v)}
  get inputRecordDateStart(){ return SearchLocationStore.inputRecordDateStart}
  set inputRecordDateStart(v: Date | null){ SearchLocationStore.INPUT_RECORD_DATE_START(v)}
  get inputRecordDateEnd(){ return SearchLocationStore.inputRecordDateEnd}
  set inputRecordDateEnd(v: Date | null){ SearchLocationStore.INPUT_RECORD_DATE_END(v)}

  dateFormatter(date: Date) {
    return format(date, "yyyy-MM-dd - HH:mm:ss");
  }

  async uiSearchBNLocation() {
    //trim
    this.inputBiobankNo = this.inputBiobankNo.trim()
    this.inputPatientBN = this.inputPatientBN.trim().toUpperCase()
    this.inputVisitTime = this.inputVisitTime.trim()
    this.inputTubeNo = this.inputTubeNo.trim()

    //validate
    let passValidate = true
    if(this.inputAdmitDateStart !== null && this.inputAdmitDateEnd !== null){
      if(this.inputAdmitDateStart > this.inputAdmitDateEnd){
        passValidate = false
        this.searchLocationMessage = '"Admit Date Start" must be lower than "Admit Date End"'
      }
    }

    if(this.inputRecordDateStart && this.inputRecordDateEnd){
      if(this.inputRecordDateStart > this.inputRecordDateEnd){
        passValidate = false
        this.searchLocationMessage = '"Record Date Start" must be lower than "Record Date End"'
      }
    }

    if(!passValidate)
      return

    this.$router.push({
      name: "searchBNResult",
      query: {
        biobankNo: this.inputBiobankNo,
        projectId: this.inputProjectId || "",
        diseaseId: this.inputDiseaseId || "",
        patientBN: this.inputPatientBN,
        visitTime: this.inputVisitTime,
        biospecimenTypeId: this.inputSampleCode || "",
        tubeNo: this.inputTubeNo,
        tubeStatus: this.inputTubeStatus || "",
        migrate: this.inputMigrate || '',
        admitDateStart: this.inputAdmitDateStart?.toISOString() || '',
        admitDateEnd: this.inputAdmitDateEnd?.toISOString() || '',
        recordDateStart: this.inputRecordDateStart?.toISOString() || '',
        recordDateEnd: this.inputRecordDateEnd?.toISOString() || '',
        showPage: "1",
        showPerPage: "25",
        sortBy: "biobankNo",
        orderBy: '1'
        },
    });
  }

  get projects() {
    return AddProjectStore.projects;
  }

  get diseases() {
    return AddProjectStore.diseases;
  }

  get biospecimenTypes() {
    return AddBiospecimenStore.biospecimenTypes;
  }

  get searchedBNs() {
    return SearchLocationStore.searchedBNs;
  }
}
