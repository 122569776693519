











































import sleep from "sleep-promise";
import { Vue, Component, Prop } from "vue-property-decorator";
import UpdateDiseaseStore from "../../store/UpdateDisease";
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class EditDiseaseModal extends Vue {
  @Prop() readonly onClose: Function | undefined;
  @Prop() readonly diseaseCode: string | undefined;
  @Prop() readonly diseaseName: string | undefined;

  inputDiseaseCode = "";
  inputDiseaseName = "";
  inputDiseaseNameFailed = false;
  updateResult = "";

  authenedUserHasPms = authenedUserHasPms

  mounted() {
    this.inputDiseaseCode = this.diseaseCode || "";
    this.inputDiseaseName = this.diseaseName || "";
  }

  async updateDisease() {
    this.updateResult = "";
    this.inputDiseaseNameFailed = false;
    let passValidate = true;
    await sleep(250);

    this.inputDiseaseName = this.inputDiseaseName.trim();

    //validate
    if (!this.inputDiseaseName) {
      passValidate = false;
      this.inputDiseaseNameFailed = true;
    }
    if (!passValidate) {
      this.updateResult = "Please fill all required data correctly";
      return;
    }

    const result = await UpdateDiseaseStore.updateDisease({
      code: this.inputDiseaseCode || "",
      name: this.inputDiseaseName || "",
    });
    if (result.status === "failure") this.updateResult = result.message;
    else if (result.status === "success") {
      this.$emit('close')
      this.$router
        .push({
          name: "diseasesUpdated",
          params: { updatedDisease: this.inputDiseaseCode },
        })
        .catch(() => this.$router.go(0));
    }
  }

  openConfirmDelete() {
    this.$buefy.dialog.confirm({
      title: "Deleting Disease",
      message:
        "Are you sure you want to <b>delete</b> disease? This action cannot be undone.",
      confirmText: "Delete",
      type: "is-danger",
      hasIcon: true,
      onConfirm: () => this.deleteDisease(),
    });
  }

  async deleteDisease() {
    this.updateResult = "";
    sleep(250);
    const result = await UpdateDiseaseStore.deleteDisease({
      code: this.diseaseCode || "",
    });

    if (result.status === "success") {
      this.$emit('close')
      this.$router
        .push({
          name: "diseasesDeleted",
        })
        .catch(() => this.$router.go(0));
    } else if (result.status === "failure") {
      this.updateResult = result.message;
    }
  }
}
