import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface APostAddStaff {
  prename: string;
  firstname: string;
  lastname: string;
}

export interface Staff {
  _id: string;
  prename: string;
  firstname: string;
  lastname: string;
  dateStr: string;
}

@Module({ name: 'AddStaffModule', store, dynamic: true, namespaced: true })
class AddStaffStore extends VuexModule {
  staffs: Array<Staff> = []
  loadingFetchStaffs = false

  @Mutation
  FETCH_STAFFS(payload: Array<Staff>) {
    this.staffs = payload
  }

  @Mutation
  SET_LOADING_FETCH_STAFFS(v: boolean) {
    this.loadingFetchStaffs = v
  }

  @Action
  async postAddStaff(payload: APostAddStaff) {
    return axios.post(
      process.env.VUE_APP_API_BASE_URL +
      process.env.VUE_APP_API_STAFF_ADD,
      {
        ...payload
      },
      {
        headers: { token: loadToken() }
      }
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err?.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to add staff'
          }
      })
  }

  @Action
  async fetchStaffs() {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_STAFF_LIST
    this.SET_LOADING_FETCH_STAFFS(true)
    return axios.get(
      url,
      {
        headers: {
          token: loadToken()
        }
      }
    )
      .then(resp => {
        this.FETCH_STAFFS(resp.data.staffs)
        this.SET_LOADING_FETCH_STAFFS(false)
        return resp.data
      })
      .catch(err => {
        this.SET_LOADING_FETCH_STAFFS(false)
        if (err?.response)
          return err.response.data
        else
          return { status: 'failure', message: 'Failed to get staffs' }
      })
  }
}

export default getModule(AddStaffStore)