<template>
  <div style="max-height: 335px; overflow-y:scroll">
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :height="projects.length * 35"
    />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const ProjectChart = {
  name: "ProjectChart",
  components: {
    Bar,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "No. of BN(s)",
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
            data: this.projects,
            axis: "y",
            parsing: {
              xAxisKey: "bnsCount",
              yAxisKey: "projectId",
            },
            categoryPercentage: 0.75,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          datalabels: {
            align: "start",
            anchor: "end",
            font: {
              weight: "bold",
            },
            formatter: (value) => {
              return value.bnsCount;
            },
            display: function(context) {
              const all = context.dataset.data.map((x) => x.bnsCount);
              const max = Math.max(...all);
              return (
                context.dataset.data[context.dataIndex].bnsCount / max > 0.07
              );
            },
          },
          tooltip: {
            callbacks: {
              label: (ctx) => {
                return `${ctx.raw.bnsCount.toLocaleString()} BN (${
                  ctx.raw.owner
                })`;
              },
            },
          },
        },
      },
    };
  },
};

export default ProjectChart;
</script>
