

















































































































import { Vue, Component } from "vue-property-decorator";
import GenerateBNStore from "../../store/GenerateBN";
import GenerateBNSteps from "../../components/GenerateBNSteps.vue";
import { parse, format } from "date-fns";
import sleep from 'sleep-promise'

@Component({
  components: { GenerateBNSteps },
})
export default class GenerateBN extends Vue {
  inputProjectId: string | null = null;
  inputDisease = "";
  inputDiseaseCode = "";
  inputPatientNo = "";
  inputPatientNoFailed = false
  inputVisitTime = "";
  inputVisitTimeFailed = false;
  inputBiospecimenTypeIndex: number | null = null;
  inputBiospecimenTypeIndexFailed = false;
  inputTubeNo = "";
  inputTubeNoFailed = false
  inputMigrate = false
  postResult = "";

  generateBNStep = 0;
  currentDate: Date = new Date();
  inputDateFailed = false;
  currentTime: Date = new Date();
  inputTimeFailed = false;

  //prevent user to select month or year but not day
  changedMonthOrYear = false
  lastSetDayTime = new Date()

  async mounted() {
    //set default date and time
    this.currentDate = new Date();

    //steps animation
    setTimeout(() => (this.generateBNStep = 1), 50);

    GenerateBNStore.RESET();
    GenerateBNStore.fetchPreGenData();
    const result = await GenerateBNStore.fetchProjectPreGenData(
      this.$route.params.projectId
    );
    if (result.status === "success") {
      this.inputDisease = GenerateBNStore.disease?.name || "";
      this.inputDiseaseCode = GenerateBNStore.disease?.code || "";
      this.inputPatientNo = GenerateBNStore.patientNo?.toString() || "";
    }
    

    this.inputProjectId = this.$route.params.projectId;
  }

  customDateParser(dateStr: string) {
    const dateOnly = parse(dateStr, "yyyy-MM-dd", this.currentDate);
    this.currentDate = new Date(dateOnly.getFullYear(), dateOnly.getMonth(), dateOnly.getDate())
    return this.currentDate;
  }

  dateFormatter(date: Date) {
    return format(date, "yyyy-MM-dd");
  }

  changeMonthOrYear(){
    if(Date.now() - this.lastSetDayTime.valueOf() > 500){
      this.changedMonthOrYear = true
    }
  }

  changeDay(){
    this.changedMonthOrYear = false
    this.lastSetDayTime = new Date()
  }

  async uiGenerateBN() {
    this.postResult = "";
    let passValidate = true
    this.inputPatientNoFailed = false
    this.inputVisitTimeFailed = false
    this.inputBiospecimenTypeIndexFailed = false
    this.inputTubeNoFailed = false
    this.inputDateFailed = false
    this.inputTimeFailed = false
    await sleep(250)
    //validate inputs
    if (
      this.inputProjectId === null ||
      this.inputDisease === "" ||
      this.inputDiseaseCode.length !== 2)
    {
      passValidate = false
    }
    if (!this.inputPatientNo
    || isNaN(parseInt(this.inputPatientNo))
    || parseInt(this.inputPatientNo) < 1){
      this.inputPatientNoFailed = true
      passValidate = false
    }
    if(this.inputBiospecimenTypeIndex === null){
      this.inputBiospecimenTypeIndexFailed = true
      passValidate = false
    }
    if(!this.inputVisitTime
    || isNaN(parseInt(this.inputVisitTime))
    || parseInt(this.inputVisitTime) <= 0
    || parseInt(this.inputVisitTime) >= 100){
      this.inputVisitTimeFailed = true
      passValidate = false
    }

    if(!this.inputTubeNo){
      this.inputTubeNoFailed = true
      passValidate = false
    }

    //process tube no : single or range
    let tubeNo = null;
    let tubeNoUpperBound = null;
    let parseTubeNoError = false;
    //remove space
    this.inputTubeNo = this.inputTubeNo.split(" ").join("");
    //add single tube
    if (!isNaN(Number(this.inputTubeNo))
    && +this.inputTubeNo > 0
    && +this.inputTubeNo < 100) {
      tubeNo = Number(this.inputTubeNo);
    } else {
      //add multiple tube
      const splited = this.inputTubeNo.split("-");
      if (
        splited.length === 2
        && !isNaN(Number(splited[0]))
        && +splited[0] > 0 && +splited[0] < 100
        && !isNaN(Number(splited[1]))
        && +splited[1] > 0 && +splited[1] < 100
      ) {
        tubeNo = Number(splited[0]);
        tubeNoUpperBound = Number(splited[1]);
        if (tubeNo >= tubeNoUpperBound) parseTubeNoError = true;
      } else parseTubeNoError = true;
    }
    if (parseTubeNoError) {
      this.inputTubeNoFailed = true
      passValidate = false
    }

    if(!this.currentDate || isNaN(this.currentDate.getDate())) {
      this.inputDateFailed = true
      passValidate = false
    }

    if(!this.currentTime || isNaN(this.currentTime.getDate())) {
      this.inputTimeFailed = true
      passValidate = false
    }

    if(!passValidate || this.inputBiospecimenTypeIndex===null || !this.inputProjectId){
      this.postResult = 'Please fill all data correctly'
      return
    }

    //warn user to select day if not
    if(this.changedMonthOrYear){
      this.$buefy.dialog.alert({
                title: 'Selecting Admit Date',
                message: 'You set <b>month</b> or <b>year</b> but did not set <b>day</b>. Please recheck admit date again before submitting',
                type: 'is-warning',
                hasIcon: true,
                icon: 'exclamation-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
      this.changedMonthOrYear = false
      return
    }

    //extract biospecimen data
    const biospecimenId = this.biospecimenTypes[this.inputBiospecimenTypeIndex]
      ._id;
    const biospecimenCode = this.biospecimenTypes[
      this.inputBiospecimenTypeIndex
    ].type;

    //start posting
    const result = await GenerateBNStore.postGenerateBN({
      projectId: this.inputProjectId,
      diseaseId: GenerateBNStore.disease?._id || "",
      diseaseCode: this.inputDiseaseCode,
      patientNo: +this.inputPatientNo,
      visitTime: +this.inputVisitTime,
      biospecimenType: biospecimenCode,
      biospecimenTypeId: biospecimenId,
      tubeNo,
      tubeNoUpperBound,
      admitDate: this.currentDateTime,
      migrate: this.inputMigrate
    });

    this.postResult = result.message;
    if (result.status === "success") {
      const newGeneratedBN = this.newGeneratedBN;
      if (!this.newGeneratedTubeLowerBound || !this.newGeneratedTubeUpperBound)
        this.$router.push(
          `/addLocation/${this.$route.params.projectId}/${newGeneratedBN}/`
        );
      else
        this.$router.push(
          `/addLocation/${this.$route.params.projectId}/${
            this.newGeneratedTubeLowerBound
          }/${this.newGeneratedTubeUpperBound}/${JSON.stringify(
            GenerateBNStore.newGeneratedBNs
          )}`
        );
    }
  }

  get projects() {
    return GenerateBNStore.projects;
  }

  get biospecimenTypes() {
    return GenerateBNStore.biospecimenTypes;
  }

  get fetchedProjectPreGenData() {
    return GenerateBNStore.fetchedProjectPreGenData;
  }

  get locations() {
    return GenerateBNStore.locations;
  }

  get newGeneratedBN() {
    return GenerateBNStore.newGeneratedBN;
  }

  get newGeneratedTubeLowerBound() {
    return GenerateBNStore.newGeneratedTubeLowerBound;
  }
  get newGeneratedTubeUpperBound() {
    return GenerateBNStore.newGeneratedTubeUpperBound;
  }

  get currentDateTime(): Date {
    const output = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate(), this.currentTime.getHours(), this.currentTime.getMinutes(), this.currentTime.getSeconds());
    return output;
  }
}
