import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'
import { BN } from './FetchBNs'
import {APostSearchTube} from './SearchLocation'

@Module({name: 'BasketModule', store, dynamic: true, namespaced: true})
class BasketModule extends VuexModule {

  bnsBasket: BN[] = []

  @Mutation
  CLEAR_BASKET(){
    this.bnsBasket = []
  }

  //add bns to basket that is not already there
  @Mutation
  POST_SEARCH_TUBE_SHOW_ALL(payload: {bns: BN[]}){
    const biobankNos: Record<string, boolean> = {}
    for( const bn of this.bnsBasket) {
      biobankNos[bn.biobankNo] = true
    }
    //add only sample that is not yet added
    this.bnsBasket = [...this.bnsBasket,
      ...payload.bns.filter(x => !biobankNos[x.biobankNo])
    ]
  }

  @Mutation
  ADD_TO_BASKET(payload: {bn: BN}){
    if (!this.bnsBasket.find(x => x.biobankNo === payload.bn.biobankNo)) {
      this.bnsBasket = [...this.bnsBasket, payload.bn]
    }
  }

  @Mutation
  DELETE_FROM_BASKET(payload: {biobankNo: string}){
    this.bnsBasket = this.bnsBasket.filter(x => x.biobankNo !== payload.biobankNo)
  }

  @Action
  toggleAddingToBasket(payload: {bn: BN}){
    if (this.bnsBasket.find(x => x.biobankNo === payload.bn.biobankNo)) {
      this.DELETE_FROM_BASKET({biobankNo: payload.bn.biobankNo})
    } else {
      this.ADD_TO_BASKET({bn: payload.bn})
    }
  }

  @Action
  async postSearchTubeShowAll(payload: APostSearchTube){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_SEARCH
    return axios.get(url, {
      headers: {token: loadToken() },
      params: {
        ...payload, showPage: 1, showPerPage: 999999
      }
    })
      .then(resp => {
        this.POST_SEARCH_TUBE_SHOW_ALL({bns: resp.data.bns})
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.resonse.data
        else
          return {status: 'failure', message: 'Failed to add sample(s) to print list'}
      })
  }

  @Action
  async printBN({bns}: {bns: string[]}){
    const url = process.env.VUE_APP_API_BASE_URL +
      process.env.VUE_APP_API_BN_PRINT
    return axios.post(
      url,
      {
        bns
      },
      {
        headers: {token: loadToken()}
      }
    )
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      if (err?.response)
        return err.response.data
      else
        return {
          status: 'failure',
          message: 'An error occurred. Please try again later.'
        }
    })
  }

}

export default getModule(BasketModule)