

















import { Vue, Component, Prop } from 'vue-property-decorator'
import {Role} from '../../store/UserRole'
import {formatDate, formatTime} from '../../utils/Time'
import AddRoleModal, {RoleModalType} from './AddRoleModal.vue'

@Component
export default class RolesAndPermissionsTableSkeleton extends Vue {
  @Prop() readonly roles: Role[] | undefined;
  formatDate = formatDate
  formatTime = formatTime

  uiOpenEditRoleModal(row: Role) {
    this.$buefy.modal.open({
      parent: this,
      component: AddRoleModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        type: 'edit' as RoleModalType,
        role: row,
      }
    })
  }
}
