























import { Vue, Component } from "vue-property-decorator";
import SearchLocationStore from "../../store/SearchLocation";
import SearchBNLocation from './SearchBNLocation.vue'

@Component({
  components: {SearchBNLocation}
})
export default class SearchLocation extends Vue {
  inputProjectId: string | null = null;

  mounted() {
    SearchLocationStore.fetchLocations();
  }

  onLocationIdSelectChange(selectedLocationId: string) {
    this.$router.push(`/box/${selectedLocationId}`);
  }

  get locations() {
    return SearchLocationStore.locations;
  }
}
