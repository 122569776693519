import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface Biospecimen {
  _id: string;
  type: string;
}

interface Disease {
  code: string;
  name: string;
  _id: string;
}

export interface BN {
  biobankNo: string;
  projectId: string;
  disease: Disease;
  biospecimen: string;
  patientNo: string;
  patientBN: string;
  visitTime: number;
  biospecimenType: Biospecimen;
  tubeNo: number;
  dateStr: string;
  locationId: string | null;
  status: string;
  admitDateStr: string;
  migrate: boolean;
}

interface AFetchBNs {
  showPage?: number;
  showPerPage?: number;
}

@Module({ name: 'FetchBNsModule', store, dynamic: true, namespaced: true })
class FetchBNsStore extends VuexModule {
  BNs: Array<BN> = []
  bnCount: number | null = null
  loading = false

  @Mutation
  FETCH_BNS(payload: { BNs: Array<BN>; bnCount: number }) {
    this.BNs = payload.BNs
    this.bnCount = payload.bnCount
  }

  @Mutation
  SET_LOADING(loading: boolean) {
    this.loading = loading
  }

  @Action
  async fetchBNs(payload: AFetchBNs) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_LIST
    this.SET_LOADING(true)
    return axios.get(
      url,
      {
        headers: {
          token: loadToken()
        },
        params: {
          ...payload
        }
      }
    )
      .then(resp => {
        this.FETCH_BNS({
          BNs: resp.data.BNs,
          bnCount: resp.data.bnCount
        })
        this.SET_LOADING(false)
      })
      .catch(err => {
        console.log(err)
        this.SET_LOADING(false)
      })
  }

}

export default getModule(FetchBNsStore)
