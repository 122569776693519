

































































import { Vue, Component, Prop } from "vue-property-decorator";
import UserRole, { Role } from "../../store/UserRole";
import sleep from "sleep-promise";

export type RoleModalType = "add" | "edit";

@Component
export default class AddRoleModal extends Vue {
  @Prop() readonly type: RoleModalType | undefined;
  //prop for editing role
  @Prop() readonly role: Role | undefined;

  inputRoleName = "";
  inputRoleNameFailed = false;
  inputPmsAdd = false;
  inputPmsRemove = false;
  inputPmsEdit = false;
  inputPmsMove = false;
  inputPmsTake = false;
  inputPmsUserAndRole = false;

  postResult = "";

  mounted() {
    if (this.type === "edit" && this.role && this.role._id) {
      this.inputRoleName = this.role.roleName;
      this.inputPmsAdd = this.role.permissions.add;
      this.inputPmsRemove = this.role.permissions.remove;
      this.inputPmsEdit = this.role.permissions.edit;
      this.inputPmsMove = this.role.permissions.move;
      this.inputPmsTake = this.role.permissions.take;
      this.inputPmsUserAndRole = this.role.permissions.userAndRole;
    }
  }

  async uiPostAddOrEditRole() {
    this.postResult = "";
    this.inputRoleNameFailed = false;
    let passValidate = true;
    await sleep(250);

    //trim
    this.inputRoleName = this.inputRoleName.trim();
    //validate
    const regex = new RegExp("^[A-Za-z0-9 ]*$");
    //wip regex with space inside
    if (!this.inputRoleName || !regex.test(this.inputRoleName)) {
      passValidate = false;
      this.inputRoleNameFailed = true;
    }
    if (!passValidate) {
      this.postResult = "Please fill all required data correctly";
      return;
    }

    let result: any = null; //result from both add and edit
    let redirectRoute = ""; //differ by add and edit
    if (this.type === "add") {
      redirectRoute = "/rolesAndPermissions?newRole=true";
      result = await UserRole.postAddRole({
        roleName: this.inputRoleName,
        permissions: {
          add: this.inputPmsAdd,
          remove: this.inputPmsRemove,
          edit: this.inputPmsEdit,
          move: this.inputPmsMove,
          take: this.inputPmsTake,
          userAndRole: this.inputPmsUserAndRole,
        },
        isRoot: false
      });
    } else if (this.type === "edit" && this.role && this.role._id) {
      redirectRoute = "/rolesAndPermissions?updateRole=true";
      result = await UserRole.postEditRole({
        _id: this.role._id,
        roleName: this.inputRoleName,
        permissions: {
          add: this.inputPmsAdd,
          remove: this.inputPmsRemove,
          edit: this.inputPmsEdit,
          move: this.inputPmsMove,
          take: this.inputPmsTake,
          userAndRole: this.inputPmsUserAndRole,
        },
        isRoot: false,
      });
    }

    if (result.status === "success") {
      this.$emit("close");
      let refresh = false;
      await this.$router.push(redirectRoute).catch(() => {
        this.$router.go(0);
        refresh = true;
      });
      //if not refresh / reload roles
      if (!refresh) await UserRole.fetchRoles();
    } else if (result.status === "failure") {
      this.postResult = result.message;
    }
  }

  get isEditingRoot() {
    return this.type === 'edit' && this.role && this.role.isRoot
  }
}
