




import { Vue, Component } from "vue-property-decorator";
import * as Three from "three";

@Component
export default class LocationVisualizer extends Vue {
  camera: any = null;
  scene: any = null;
  renderer: any = null;
  drawers: Array<any> = [];

  init() {
    const container = document.getElementById("container");
    if (!container) return;

    const width = 600;
    const height = 400;

    this.scene = new Three.Scene();
    this.camera = new Three.PerspectiveCamera(75, width / height, 0.1, 10000);

    //box
    this.initDrawers(4,4)
    this.camera.position.z = 7;

    //lights
    const sphere = new Three.SphereGeometry(0.5, 0.5, 0.5);
    const light1 = new Three.PointLight(0xffffff, 2, 0);
    light1.add(
      new Three.Mesh(sphere, new Three.MeshBasicMaterial({ color: 0xff0040 }))
    );
    light1.position.set(2, 2, 2);
    this.scene.add(light1);

    //add child
    this.renderer = new Three.WebGLRenderer({ antialias: true });
    this.renderer.setSize(width, height);
    container.appendChild(this.renderer.domElement);
  }

  animate(elapsed: number) {
    requestAnimationFrame(this.animate);
    this.camera.position.x -= 0.01
    this.camera.position.z -= 0.005
    this.camera.rotation.y -= 0.005
    this.renderer.render(this.scene, this.camera);
  }

  initDrawers(width: number, height: number) {
    const drawerWidth = 1
    const drawerHeight = 1.75
    const drawerDepth = 2

    const startXPos = drawerWidth * -1.5
    const startYPos = drawerHeight * -1.5

    for (let i = 0; i < height; i++) {
      this.drawers.push([])
      for (let j = 0; j < width; j++) {
        const geometry = new Three.BoxGeometry(drawerWidth, drawerHeight, drawerDepth);
        const material = new Three.MeshLambertMaterial({ color: 0xbababa });
        const mesh = new Three.Mesh(geometry, material);
        mesh.position.x = startXPos + (j * (drawerWidth + 0.01) )
        mesh.position.y = startYPos + (i * (drawerHeight+ 0.01) )
        this.scene.add(mesh)
        this.drawers[i].push(mesh)
      }
    }
  }

  mounted() {
    this.init();
    this.animate(0);
  }
}
