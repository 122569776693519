































import { Vue, Component, Prop } from "vue-property-decorator";
import {UserAccount} from '../../store/FetchUserAccounts'
import {format} from 'date-fns'
import EditUserAccountModal from './EditUserAccountModal.vue'

@Component
export default class UserAccountsTableSkeleton extends Vue{
  @Prop() readonly userAccounts: Array<UserAccount> | undefined;


  openEditModal(user: UserAccount){
    this.$buefy.modal.open({
      parent: this,
      component: EditUserAccountModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        ...user
      }
    });
  }


  get processedUserAccounts(){
    if (this.userAccounts){
      return this.userAccounts.map(
        (x, i) => ({
          ...x, index : i + 1, password : '******'
        })
      )
    }
    else 
      return []
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }

  sortByUserFullname(a: UserAccount, b: UserAccount, isAsc: boolean) {
    if (isAsc)
      return a.firstname.toLowerCase() + ' ' + a.lastname.toLowerCase() > b.firstname.toLowerCase() + ' ' + b.lastname.toLowerCase() ? 1 : -1
    else 
      return a.firstname.toLowerCase() + ' ' + a.lastname.toLowerCase() < b.firstname.toLowerCase() + ' ' + b.lastname.toLowerCase() ? 1 : -1
  }
}
