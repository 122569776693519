






































import { Vue, Component } from "vue-property-decorator";
import UserAccountsTableSkeleton from "./UserAccountsTableSkeleton.vue";
import FetchUserAccountsStore from "../../store/FetchUserAccounts";

@Component({
  components: { UserAccountsTableSkeleton },
})
export default class UserAccounts extends Vue {
  async mounted() {
    const result = await FetchUserAccountsStore.fetchUserAccounts();
  }

  get userAccounts() {
    return FetchUserAccountsStore.userAccounts;
  }

  get updatedUsername() {
    if (this.$route.params.updatedUsername)
      return this.$route.params.updatedUsername;
    else return null;
  }

  get loading() {
    return FetchUserAccountsStore.loading;
  }
}
