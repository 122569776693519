

























































import { Vue, Component, Prop } from "vue-property-decorator";
import FetchProjectsStore, { Project } from "../../store/FetchProjects";
import AuthenticateStore from "../../store/Authenticate";
import { format } from "date-fns";
import { EditerModal } from "../../classes/EditerModal";
import EditProjectModal from "./EditProjectModal.vue";
import {authenedUserHasPms} from '../../utils/Role'

interface EditorModalProps {
  projectId: string;
  piId: string;
  diseaseId: string;
}

@Component
export default class ProjectTable extends Vue {
  @Prop(Number) readonly limit: number | undefined;
  @Prop(Boolean) readonly isSortable: boolean | undefined;

  authenedUserHasPms = authenedUserHasPms

  async mounted() {
      await FetchProjectsStore.fetchProjects({
        sortBy: "createdAt",
        orderBy: -1,
        limit: this.limit,
      });
  }

  openEditProjectModal(row: Project) {
    this.$buefy.modal.open({
      parent: this,
      component: EditProjectModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        projectId: row.id,
        piId: row.piId,
        diseaseId: row.diseaseId,
      }
    });
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }

  sortByPIFullName(a: Project, b: Project, isAsc: boolean) {
    const as = a.pi.split(' ')
    const bs = b.pi.split(' ')
    if (isAsc)
      return as[1].toLowerCase() + ' ' + as[2].toLowerCase() > bs[1].toLowerCase() + ' ' + bs[2].toLowerCase() ? 1 : -1
    else 
      return as[1].toLowerCase() + ' ' + as[2].toLowerCase() < bs[1].toLowerCase() + ' ' + bs[2].toLowerCase() ? 1 : -1
  }

  get projects() {
    return FetchProjectsStore.projects.map((x, i) => ({
      ...x,
      index: i + 1,
    }));
  }

  get loading() {
    return FetchProjectsStore.loading;
  }

}
