






























import { Vue, Component } from "vue-property-decorator";
import AddDiseaseStore from '../../store/AddDisease'
import sleep from 'sleep-promise'

@Component
export default class AddDisease extends Vue{
  inputDiseaseCode = ''
  inputDiseaseCodeFailed = false
  inputDiseaseName = ''
  inputDiseaseNameFailed = false
  postAddDiseaseResult = ''

  async uiPostAddDisease(){
    this.postAddDiseaseResult = ''
    this.inputDiseaseCodeFailed = false
    this.inputDiseaseNameFailed = false
    let passValidate = true
    await sleep(250)

    //trim
    this.inputDiseaseCode = this.inputDiseaseCode.trim().toUpperCase()
    this.inputDiseaseName = this.inputDiseaseName.trim()

    //validate
    if(this.inputDiseaseCode.length !== 2){
      passValidate = false
      this.inputDiseaseCodeFailed = true
    }
    if(!this.inputDiseaseName){
      passValidate = false
      this.inputDiseaseNameFailed = true
    }

    if(!passValidate){
      this.postAddDiseaseResult = 'Please fill all required data'
      return
    }

    const result = await AddDiseaseStore.postAddDisease({
      diseaseCode: this.inputDiseaseCode,
      diseaseName: this.inputDiseaseName
    })

    if(result.status === 'success')
      this.$router.push({name: 'diseases', query:{newDisease:'true'}})
    else{
      this.postAddDiseaseResult = result.message
    }
  }
}
