







































import { Vue, Component } from "vue-property-decorator";
import AddBiospecimenStore from "../../store/AddBiospecimen";
import sleep from "sleep-promise";

@Component
export default class AddBiospecimenType extends Vue {
  inputBiospecimenCode = "";
  inputBiospecimenCodeFailed = false;
  inputBiospecimenName = "";
  inputBiospecimenNameFailed = false;
  postResult = "";

  async uiPostAddBiospecimenType() {
    this.postResult = "";
    this.inputBiospecimenCodeFailed = false;
    this.inputBiospecimenNameFailed = false;
    let passValidate = true;
    await sleep(250);

    //trim
    this.inputBiospecimenCode = this.inputBiospecimenCode.trim().toUpperCase();
    this.inputBiospecimenName = this.inputBiospecimenName.trim();

    //validate
    if (this.inputBiospecimenCode.length !== 3) {
      passValidate = false;
      this.inputBiospecimenCodeFailed = true;
    }
    if (!this.inputBiospecimenName) {
      passValidate = false;
      this.inputBiospecimenNameFailed = true;
    }
    if (!passValidate) {
      this.postResult = "Please fill all required data correctly";
      return;
    }

    const result = await AddBiospecimenStore.postAddBiospecimenType({
      biospecimenCode: this.inputBiospecimenCode,
      biospecimenName: this.inputBiospecimenName,
    });

    if (result.status === "success") {
      this.$router.push({
        name: "biospecimenTypes",
        query: { newBiospecimenType: "true" },
      });
    } else {
      this.postResult = result.message;
    }
  }
}
