







































import { Vue, Component } from "vue-property-decorator";
import { component } from "vue/types/umd";
import BiospecimenTypesTable from "./BiospecimenTypesTable.vue";

@Component({
  components: { BiospecimenTypesTable },
})
export default class BiospecimenTypes extends Vue {}
