














































import { Vue, Component } from "vue-property-decorator";
import FetchLocationsStore from "../../store/FetchLocations";
import AddLocationStore from "../../store/AddLocation";
import BasketStore from "../../store/Basket";

@Component
export default class AssignTubesFromBasket extends Vue {
  inputSelectBox: string | null = null;
  postResult = "";

  mounted() {
    FetchLocationsStore.RESET();
    FetchLocationsStore.fetchLocations({ sortBy: "id", orderBy: 1 });
    AddLocationStore.RESET();
  }

  async onLocationIdSelectChange(locationId: string) {
    this.postResult = "";

    const result = await AddLocationStore.postAddTubes({
      biobankNos: this.bnsBasketBiobankNo,
      locationId,
    });

    if (result.status === "success") {
      const locationXs: Array<string> = [];
      const locationYs: Array<string> = [];
      const unassignedBNs: Array<string> = [];

      for (const bn of result.bns) {
        if (bn.location) {
          locationXs.push(bn.location.foundColumn);
          locationYs.push(bn.location.foundRow);
        } else {
          unassignedBNs.push(bn.biobankNo);
        }
      }

      //clear basket when assign success
      BasketStore.CLEAR_BASKET()

      this.$router.push({
        name: "boxNewMultipleBNs",
        params: {
          locationId,
          locationXs: JSON.stringify(locationXs),
          locationYs: JSON.stringify(locationYs),
          unassignedBNs: JSON.stringify(unassignedBNs),
        },
      });
    } else {
      this.postResult = result.message;
      this.inputSelectBox = null
    }
  }

  get locations() {
    return FetchLocationsStore.locations;
  }

  get bnsBasket() {
    return BasketStore.bnsBasket;
  }

  get bnsBasketBiobankNo() {
    return this.bnsBasket.map((x) => x.biobankNo);
  }
}
