import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface AUpdateStaff{
  _id: string;
  prename: string;
  firstname: string;
  lastname: string;
}

interface ADeleteStaff{
  _id: string;
}

@Module({name: 'UpdateStaffModule', store, dynamic: true, namespaced: true})
class UpdateStaffStore extends VuexModule{
  @Action updateStaff(payload: AUpdateStaff){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_STAFF_UPDATE
    return axios.put(
      url, {...payload}, { headers: {token: loadToken()}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to update staff. Please try again later.'
          }
      })
  }

  @Action deleteStaff(payload: ADeleteStaff){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_STAFF_DELETE
    return axios.delete(
      url, { headers: {token: loadToken()}, params: {...payload}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to delete Principal Investigators (PI)'
          }
      })
  }
}

export default getModule(UpdateStaffStore)
