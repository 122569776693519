














import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Shelfs extends Vue {
  @Prop() readonly activate: number | undefined;
}
