import {Role, Action} from '../store/UserRole'
import AuthenticateStore from '../store/Authenticate'

export function computeRoleOptionText(role: Role) { 
  return Object.entries(role.permissions).filter( ([k,v]) => v)
    .map(([k,v]) => {
      if (k === 'userAndRole') return 'User Account & Role'
      else return k.substr(0, 1).toUpperCase() + k.substr(1)
    })
    .join(', ')
}

export function authenedUserHasPms(pms: Action) {
  if (AuthenticateStore.signedInPms) {
    return AuthenticateStore.signedInPms[pms]
  }
  else return false
}