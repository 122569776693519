













































import { Vue, Component } from "vue-property-decorator";
import authenticateStore, { loadToken } from "../store/Authenticate";
import sleep from "sleep-promise";

@Component
export default class Authenticate extends Vue {
  inputUsername = "";
  inputPassword = "";
  postResult = "";

  async mounted() {
    const success = await authenticateStore.reSignIn(loadToken() || null);
    if (success) this.$router.push("/dashboard");
  }

  async uiSignIn(e: any) {
    e.preventDefault();
    this.postResult = "";
    await sleep(250);
    this.inputUsername = this.inputUsername.trim();
    this.inputPassword = this.inputPassword.trim();
    const signInResult = await authenticateStore.signIn({
      username: this.inputUsername,
      password: this.inputPassword,
    });

    if (signInResult.status === "success") {
      this.$router.push({ path: "/dashboard" });
    } else {
      this.postResult = signInResult.message;
    }
  }
}
