import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

export interface Project {
  id: string;
  diseaseCode: string;
  disease: string;
  pi: string;
  countBNs: number;
  dateStr: string;
  piId: string;
  diseaseId: string;
}

@Module({ name: 'FetchProjectsModule', store, dynamic: true, namespaced: true })
class FetchProjectsStore extends VuexModule {
  projects: Array<Project> = []
  loading = false

  @Mutation
  FETCH_PRE_GEN_DATA(payload: Array<Project>) {
    this.projects = payload
  }

  @Mutation
  SET_LOADING(v: boolean) {
    this.loading = v
  }

  @Action
  async fetchProjects( payload: { sortBy: string; orderBy: number; limit?: number | undefined }) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_PROJECT_LIST
    this.SET_LOADING(true)
    return axios.get(
      url,
      {
        headers: {
          token: loadToken()
        },
        params: {
          ...payload
        }
      })
      .then(resp => {
        this.FETCH_PRE_GEN_DATA(resp.data.projects)
        this.SET_LOADING(false)
      })
      .catch(err => {
        console.log(err)
        this.SET_LOADING(false)
      })
  }
}

export default getModule(FetchProjectsStore)