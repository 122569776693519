import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import router from '../router'
import { Permissions } from './UserRole'

interface MSignInPayload {
  username: string;
  token: string;
  prename: string;
  firstname: string;
  lastname: string;
  permissions: Permissions;
}

interface SignInPayload {
  username: string;
  password: string;
}

@Module({ name: 'AuthenticateModule', store, dynamic: true, namespaced: true })
class AuthenticateStore extends VuexModule {
  signedInUsername:  string | null = null
  signedInPrename:   string | null = null
  signedInFirstname: string | null = null
  signedInLastname:  string | null = null
  signedInPms: Permissions | null = null
  signedIn = false

  @Mutation
  SIGN_IN({ username, token, prename, firstname, lastname, permissions }: MSignInPayload) {
    this.signedInUsername = username
    this.signedIn = true
    this.signedInPrename = prename
    this.signedInFirstname = firstname
    this.signedInLastname = lastname
    this.signedInPms = permissions
    localStorage.setItem('token', token)
  }

  @Mutation
  SIGN_OUT() {
    this.signedInUsername = null
    this.signedIn = false
    localStorage.removeItem('token')
  }

  @Action
  async signIn({ username, password }: SignInPayload) {
    return axios.post(
      process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_USER_LOGIN,
      {
        username,
        password
      }
    ).then((resp) => {
      this.SIGN_IN({
        ...resp.data
      })
      return { status: 'success', message: '' }
    }).catch((err) => {
      this.SIGN_OUT()
      let message = ''
      if (err.response?.status === 400) {
        message = err.response.data.message
      } else {
        message = 'Failed to sign in'
      }
      return { status: 'failure', message }
    })
  }

  @Action
  async reSignIn(token: string | null) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_USER_TESTTOKEN
    return axios.post(url, {},
      {
        headers: {
          token
        }
      })
      .then(resp => {
        if (resp.data.status === 'success') {
          this.SIGN_IN({
            ...resp.data
          })
          return true
        }
        else
          return false
      })
      .catch(() => false)
  }

  @Action
  async signOut(){
    this.SIGN_OUT()
    router.push('/')
  }
}

export default getModule(AuthenticateStore)

const loadToken = ()=> {
    const token = localStorage.getItem('token')
    if(!token)
      getModule(AuthenticateStore).signOut()
    else
      return token
}

export {loadToken}
