







































import { Vue, Component } from "vue-property-decorator";
import FetchLocationsStore from "../../store/FetchLocations";
import AddLocationStore from '../../store/AddLocation'

@Component
export default class AssignTube extends Vue{
  inputSelectBox: string | null = null
  postResult = ''

  mounted(){
    FetchLocationsStore.RESET()
    FetchLocationsStore.fetchLocations({sortBy:'id', orderBy:1})
    AddLocationStore.RESET()
  }

  async onLocationIdSelectChange(locationId: string){
    this.postResult = ''
    const biobankNo = this.$route.params.biobankNo
    const result = await AddLocationStore.postAddTube({biobankNo, locationId})

    if(result.status === 'success'){
      this.$router.push(
          `/box/${locationId}/${biobankNo}/${result.tubeRow}/${result.tubeColumn}`
      );
    }
    else {
      this.postResult = result.message
      this.inputSelectBox = null
    }
  }

  get locations() {
    return FetchLocationsStore.locations;
  }
}
