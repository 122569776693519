














































import sleep from 'sleep-promise'
import { Vue, Component, Prop } from "vue-property-decorator";
import UpdateStaffStore from '../../store/UpdateStaff'
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class EditStaffModal extends Vue {
  @Prop() readonly id: string | undefined;
  @Prop() readonly title: string | undefined;
  @Prop() readonly firstname: string | undefined;
  @Prop() readonly lastname: string | undefined;

  inputTitle = ''
  inputTitleFailed = false
  inputFirstname = ''
  inputFirstnameFailed = false
  inputLastname = ''
  inputLastnameFailed = false
  updateResult = ''

  authenedUserHasPms = authenedUserHasPms

  mounted(){
    this.inputTitle = this.title || ''
    this.inputFirstname = this.firstname || ''
    this.inputLastname = this.lastname || ''
  }

  async updateStaff(){
    this.updateResult = ""
    this.inputTitleFailed = false
    this.inputFirstnameFailed = false
    this.inputLastnameFailed = false
    let passValidate = true
    await sleep(250)

    this.inputTitle = this.inputTitle.trim()
    this.inputFirstname = this.inputFirstname.trim()
    this.inputLastname = this.inputLastname.trim()

    //validate 
    if(!this.inputTitle){passValidate=false;this.inputTitleFailed=true}
    if(!this.inputFirstname){passValidate=false;this.inputFirstnameFailed=true}
    if(!this.inputLastname){passValidate=false;this.inputLastnameFailed=true}
    if(!passValidate){
      this.updateResult = "Please fill all required data correctly"
      return
    }

    const result = await UpdateStaffStore.updateStaff({
      _id: this.id || '',
      prename: this.inputTitle,
      firstname: this.inputFirstname,
      lastname: this.inputLastname
    })

    if(result.status === 'failure')
      this.updateResult = result.message
    else if (result.status === 'success'){
      this.$emit('close')
      this.$router.push({
        name: 'staffsUpdated',
      }).catch(()=> this.$router.go(0))
    }
  }

  openConfirmDelete() {
    this.$buefy.dialog.confirm({
      title: "Deleting Principal Investigators (PI)",
      message:
        "Are you sure you want to <b>delete</b> PI? This action cannot be undone.",
      confirmText: "Delete",
      type: "is-danger",
      hasIcon: true,
      onConfirm: () => this.deleteStaff(),
    });
  }

  async deleteStaff(){
    this.updateResult = ''
    sleep(250)
    const result = await UpdateStaffStore.deleteStaff({
      _id: this.id || ''
    })

    if(result.status === 'success'){
      this.$emit('close')
      this.$router.push({
        name: 'staffsDeleted'
      })
      .catch(()=>this.$router.go(0))
    }
    else if(result.status === 'failure'){
      this.updateResult = result.message
    }
  }
}
