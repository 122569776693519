<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData" :height="335" />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

const SampleChart = {
  name: "SampleChart",
  components: {
    Bar,
  },
  props: {
    years: {
      type: Array,
      default: () => [],
    },
    collected: {
      type: Array,
      default: () => [],
    },
    taken: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: this.years,
        datasets: [
          {
            label: "BN(s) collected",
            backgroundColor: "rgba(153, 102, 255, 0.2)",
            borderColor: "rgb(153, 102, 255)",
            borderWidth: 1,
            data: this.collected,
          },
          {
            label: "BN(s) Taken",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 1,
            data: this.taken,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            align: "start",
            anchor: "end",
            font: {
              weight: "bold",
            },
            clip: true,
            display: function(context) {
              //todo make this dynamic and related to max value of chart
              return context.dataset.data[context.dataIndex] > 500;
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    };
  },
};

export default SampleChart;
</script>
