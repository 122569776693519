import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'
import { BN } from './FetchBNs'

interface Location {
  id: string;
  address: string;
  owner: string;
}

interface AFetchLocationByBN {
  projectId: string;
  diseaseCode: string;
  patientNo: string;
  visitTime: string;
  biospecimenType: string;
  tubeNo: string;
}

export interface APostSearchTube {
  biobankNo?: string;
  projectId?: string;
  diseaseId?: string;
  patientBN?: string;
  visitTime?: string;
  biospecimenTypeId?: string;
  tubeNo?: string;
  tubeStatus?: string;
  migrate?: string;
  admitDateStart?: Date;
  admitDateEnd?: Date;
  recordDateStart?: Date;
  recordDateEnd?: Date;
}

const showColumnOptions = {
  biobankNo: true,
  projectId: true,
  disease: true,
  patientBN: false,
  biospecimenType: true,
  visitTime: true,
  tubeNo: true,
  status: true,
  admitDate: true,
  recordDate: true,
  migrate: false
}

@Module({ name: 'SearchLocation', store, dynamic: true, namespaced: true })
class SearchLocationStore extends VuexModule {
  locations: Array<Location> = []
  fetchedLocations = false

  searchedBNs: Array<BN> = []
  searchedTube = false
  bnCount: number | null = null

  loadingPostSearchTube = false

  //permanent (use across pages)
  showColumnOptions = showColumnOptions
  inputBiobankNo = "";
  inputProjectId: string | null = null;
  inputDiseaseId: string | null = null;
  inputPatientBN = "";
  inputVisitTime = "";
  inputTubeNo = "";
  inputSampleCode: string | null = null;
  inputTubeStatus: string | null = null;
  inputMigrate: string | null = null;
  inputAdmitDateStart: Date | null = null;
  inputAdmitDateEnd: Date | null = null;
  inputRecordDateStart: Date | null = null;
  inputRecordDateEnd: Date | null = null;

  //Mutate search filter
  @Mutation INPUT_BIOBANK_NO(v: string){ this.inputBiobankNo = v}
  @Mutation INPUT_PROJECT_ID(v: string | null){ this.inputProjectId = v}
  @Mutation INPUT_DISEASE_ID(v: string | null){ this.inputDiseaseId = v}
  @Mutation INPUT_PATIENT_BN(v: string){ this.inputPatientBN = v}
  @Mutation INPUT_VISIT_TIME(v: string){ this.inputVisitTime = v}
  @Mutation INPUT_TUBE_NO(v: string){ this.inputTubeNo = v}
  @Mutation INPUT_SAMPLE_CODE(v: string | null){ this.inputSampleCode = v}
  @Mutation INPUT_TUBE_STATUS(v: string | null){ this.inputTubeStatus = v}
  @Mutation INPUT_MIGRATE(v: string | null){ this.inputMigrate = v}
  @Mutation INPUT_ADMIT_DATE_START(v: Date | null) { this.inputAdmitDateStart = v}
  @Mutation INPUT_ADMIT_DATE_END(v: Date | null) { this.inputAdmitDateEnd = v}
  @Mutation INPUT_RECORD_DATE_START(v: Date | null) { this.inputRecordDateStart = v}
  @Mutation INPUT_RECORD_DATE_END(v: Date | null) { this.inputRecordDateEnd = v}

  @Mutation
  RESET() {
    this.locations = []
    this.fetchedLocations = false
    this.searchedBNs = []
    this.searchedTube = false
    this.bnCount = null
  }

  @Mutation
  RESET_SHOW_COLOUMN_OPTIONS(){
    this.showColumnOptions = Object.assign({}, showColumnOptions)
  }

  @Mutation
  FETCH_LOCATIONS(payload: Array<Location>) {
    this.locations = payload
  }

  @Mutation
  SET_LOADING_POST_SEACH_TUBE(v: boolean) {
    this.loadingPostSearchTube = v
  }

  @Mutation
  POST_SEARCH_TUBE(payload: { searchedBNs: Array<BN>; bnCount: number }) {
    this.searchedBNs = payload.searchedBNs
    this.bnCount = payload.bnCount
    this.searchedTube = true
  }

  @Action
  async fetchLocations() {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_LIST
    return axios.get(url, {
      headers: {
        token: loadToken()
      }
    })
      .then(resp => {
        this.FETCH_LOCATIONS(resp.data.locations)
      })
      .catch(err => {
        console.log(err)
      })
  }

  @Action
  async fetchLocationByBN(payload: AFetchLocationByBN) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_SEARCHBN
    return axios.get(url, {
      headers: { token: loadToken() },
      params: {
        ...payload
      }
    })
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return { status: 'failure', message: 'Failed to search BN' }
      })
  }

  @Action
  async postSearchTube(payload: APostSearchTube) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_SEARCH
    this.SET_LOADING_POST_SEACH_TUBE(true)
    return axios.get(url, {
      headers: { token: loadToken() },
      params: {
        ...payload
      }
    })
      .then(resp => {
        this.POST_SEARCH_TUBE({ searchedBNs: resp.data.bns, bnCount: resp.data.bnCount })
        this.SET_LOADING_POST_SEACH_TUBE(false)
        return resp.data
      })
      .catch(err => {
        this.SET_LOADING_POST_SEACH_TUBE(false)
        if (err.response)
          return err.response.data
        else
          return { status: 'failure', message: 'Failed to search sample' }
      })
  }
}

export default getModule(SearchLocationStore)