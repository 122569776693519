import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'
import {BN} from './FetchBNs'
import {Location} from './FetchLocations'

interface BNExtended extends BN{
  location: Location;
}

@Module({ name: 'FetchBNModule', store, dynamic: true, namespaced: true})
class FetchBNModule extends VuexModule {
  fetchedBN: BNExtended | null = null
  
  @Mutation
  RESET(){
    this.fetchedBN = null
  }

  @Mutation
  FETCH_BN(bn: BNExtended){
    this.fetchedBN = bn
  }

  @Action
  async fetchBN(biobankNo: string){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_GET
    return axios.get(
      url,
      {
        headers: {
          token : loadToken()
        },
        params : {
          biobankNo
        }
      }
    )
      .then(resp => {
        this.FETCH_BN(resp.data.bn)
        return resp.data
      })
      .catch(err => {
        if(err.response)
          return err.response.data
        else
          return {status: 'failure', message: 'Failed to get sample data. Please try again later.'}
      })
  }
}

export default getModule(FetchBNModule)