







import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class GenerateBNSteps extends Vue {
  @Prop(Number) readonly step: number|undefined
}
