













import { Vue, Component, Prop } from "vue-property-decorator";
import FetchBNsStore from "../../store/FetchBNs";
import BNsTableSkeleton from "./BNsTableSkeleton.vue";

@Component({
  components: { BNsTableSkeleton },
})
export default class BNsTable extends Vue {
  @Prop(Number) readonly showPerPage: number | undefined;
  @Prop(Number) readonly showPage: number | undefined;
  @Prop() readonly isSortable: boolean | undefined;
  @Prop() readonly hideColumnEdit: boolean | undefined;
  @Prop() readonly hideColumnBasket: boolean | undefined;

  async mounted() {
    FetchBNsStore.fetchBNs({
      showPerPage: this.showPerPage,
      showPage: this.showPage,
    });
  }

  get BNs() {
    return FetchBNsStore.BNs.map((x, i) => ({
      ...x,
      index: i + 1,
    }));
  }

  get loading() {
    return FetchBNsStore.loading;
  }
}
