import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface AUpdateLocation{
  id: string;
  building: string;
  room: string;
  label: string;
}

interface ADeleteLocation{
  id: string;
}

@Module({ name: 'UpdateLocationModule', store, dynamic: true, namespaced: true })
class UpdateLocationStore extends VuexModule {
  @Action updateLocation(payload: AUpdateLocation){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_UPDATE
    return axios.put(
      url, {...payload}, {headers: {token: loadToken()}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'An error occurred. Please try again later.'
          }
      })
  }

  @Action deleteLocation(payload: ADeleteLocation){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_DELETE
    return axios.delete(
      url, { headers: {token: loadToken()}, params: {...payload}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to delete box location'
          }
      })
  }
}

export default getModule(UpdateLocationStore)