import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface FPostMoveTube {
  biobankNo: string;
  oldLocationId: string;
  newLocationId: string;
  row: number;
  column: number;
}

@Module({ name: 'MoveTubeStore', store, dynamic: true, namespaced: true })
class MoveTubeStore extends VuexModule {
  @Action
  async postMoveTube(payload: FPostMoveTube) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_MOVETUBE
    return axios.post(
      url, { ...payload },
      { headers: { token: loadToken() } }
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        return {
          status: 'failure', message: 'Failed to move sample'
        }
      })
  }
}

export default getModule(MoveTubeStore)