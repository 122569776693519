import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'
import { BN } from './FetchBNs'

interface User {
  prename: string;
  firstname: string;
  lastname: string;
}

export interface TubeActionLog {
  type: string;
  bn: BN;
  user: User;
}

export interface AFetchTubeActionLogs {
  showPage?: string;
  showPerPage?: string;
  sortBy?: string;
  orderBy?: string;
}

type PreGenPerformer = {
  _id?: string;
  fullName: string;
  isDeleted: boolean;
}

@Module({ name: 'FetchTubeActionLogsModule', store, dynamic: true, namespaced: true })
class FetchTubeActionLogsStore extends VuexModule {
  tubeActionLogs: Array<TubeActionLog> = []
  tubeActionLogCount: number | null = null
  preGenPerformers: PreGenPerformer[] = []
  loadingPreGenData = false
  loadingLogs = false

  @Mutation
  RESET() {
    this.tubeActionLogs = []
    this.tubeActionLogCount = null
  }

  @Mutation
  FETCH_TUBE_ACTION_LOGS(payload: { tubeActionLogs: Array<TubeActionLog>; tubeActionLogCount: number }) {
    this.tubeActionLogs = payload.tubeActionLogs
    this.tubeActionLogCount = payload.tubeActionLogCount
  }

  @Mutation
  FETCH_PRE_GEN_DATA(payload: { users: PreGenPerformer[] }) {
    this.preGenPerformers = payload.users
  }

  @Mutation
  SET_LOADING_LOGS(v: boolean) {
    this.loadingLogs = v
  }

  @Mutation
  SET_LOADING_PRE_GEN_DATA(v: boolean) {
    this.loadingPreGenData = v
  }

  @Action
  async fetchTubeActionLogs(payload: AFetchTubeActionLogs) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_TUBEACTIONLOG_LIST
    this.SET_LOADING_LOGS(true)
    return axios.get(
      url, {
      headers: {
        token: loadToken()
      },
      params: { ...payload }
    }
    )
      .then(resp => {
        this.FETCH_TUBE_ACTION_LOGS({ ...resp.data })
        this.SET_LOADING_LOGS(false)
      })
      .catch(err => {
        console.log(err)
        this.SET_LOADING_LOGS(false)
      })
  }

  @Action
  async fetchPreGenData() {
    this.SET_LOADING_PRE_GEN_DATA(true)
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_TUBEACTIONLOG_GETPREGENDATA
    return axios.get(
      url,
      {
        headers: {
          token: loadToken()
        }
      }
    )
      .then(resp => {
        this.FETCH_PRE_GEN_DATA({
          users: resp.data.users
        })
        this.SET_LOADING_PRE_GEN_DATA(false)
      })
      .catch(err => {
        this.SET_LOADING_PRE_GEN_DATA(false)
        console.error(err)
      })
  }
}

export default getModule(FetchTubeActionLogsStore)
