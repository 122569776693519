































import {Vue, Component} from 'vue-property-decorator'
import PrintBNStore from '../../store/Basket'
import sleep from 'sleep-promise'
import {previewPrint} from '../../classes/LabelPrinter'

@Component
export default class PrintBN extends Vue {
  bns: string[] = []
  notFoundBns: string[] = []
  postResult = ''

  async postPrint(){
    this.postResult = ''

    await sleep(250)
    const result = await PrintBNStore.printBN({bns: this.bns})
    if (result.status === 'success'){
      previewPrint(result.labels)
    }
    else{
      this.postResult = result.message
      if ('notFoundBns' in result){
        this.postResult += ' : '
        result.notFoundBns.map((x: string) => this.postResult += x + ' ')
        this.notFoundBns = result.notFoundBns
      }
    }
  }

  removeNotFoundBns(){
    this.bns = this.bns.filter(x => this.notFoundBns.indexOf(x) === -1)
    this.notFoundBns = []
    this.postResult = ''
  }

  onTagAdded(newBn: string){
    const splited = newBn.replaceAll(',',' ').replaceAll('\n', ' ').replaceAll('\t', ' ')
      .split(' ')
      .filter(x => x !== '')
    //split if use copy paste and contain , or space
    if(splited.length > 1){
      this.bns = this.bns.filter(x => x!== newBn)
      splited.map(x => this.bns.push(x))
    }
    //remove duplicate
    this.bns = [...new Set(this.bns)]
  }
}
