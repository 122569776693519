



































import { Vue, Component } from "vue-property-decorator";
import StaffsTable from "./StaffsTable.vue";

@Component({
  components: { StaffsTable },
})
export default class Staffs extends Vue {}
