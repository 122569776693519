









































































import { Vue, Component } from "vue-property-decorator";
import FetchLocationsStore from "../../store/FetchLocations";
import RenderBoxStore from "../../store/RenderBox";
import MoveTubeStore from "../../store/MoveTube";

@Component
export default class MoveTube extends Vue {
  selectedLocationId: string | null = null;
  postResult = ""

  mounted() {
    FetchLocationsStore.RESET();
    FetchLocationsStore.fetchLocations({ sortBy: "id", orderBy: 1 });
    RenderBoxStore.RESET();
  }

  async onLocationIdSelectChange(locationId: string) {
    await RenderBoxStore.fetchBox(locationId);
  }

  uiConfirmMoveTube(row: number, column: number) {
    this.postResult = ""
    this.$buefy.dialog.confirm({
      title: "Moving Sample",
      message: "Are you sure you want to move sample to this box?",
      confirmText: "Move sample",
      type: "is-primary",
      hasIcon: true,
      onConfirm: async () => {
        const result = await MoveTubeStore.postMoveTube({
          biobankNo: this.$route.params.biobankNo,
          oldLocationId: this.$route.params.oldLocationId,
          newLocationId: this.selectedLocationId || "",
          row,
          column,
        });

        if (result.status === "success") {
          this.$router.push({
            name: "boxNewBN",
            params: {
              locationId: this.selectedLocationId || "",
              newBN: this.$route.params.biobankNo,
              row: row.toString(), column: column.toString()
            }
          });
        } else {
          this.postResult = result.message
        }
      },
    });
  }

  get computedBox() {
    if (!this.location) return [];
    const computedBox: any[] = [];

    //init 2d empty array
    for (let i = 0; i < this.location.boxHeight; i++) {
      computedBox.push([]);
      for (let j = 0; j < this.location.boxWidth; j++) {
        computedBox[i].push(Object.assign({}, this.location.box[i][j]));
      }
    }
    return computedBox;
  }

  get locations() {
    return FetchLocationsStore.locations;
  }

  get location() {
    return RenderBoxStore.location;
  }

  get fetchedBox() {
    return RenderBoxStore.fetchedBox;
  }
}
