




































































import { Vue, Component, Prop } from "vue-property-decorator";
import VisualizeLocationStore from "../../store/VisualizeLocation";

const maxShelf = 5;
const maxRow = 5;
const maxDrawer = 5;
const maxBox = 5;

@Component
export default class FreezerVisualizerModal2 extends Vue {
  @Prop() readonly onClose: Function | undefined;
  @Prop() readonly boxId: string | undefined;

  shelf = 1;

  async mounted() {
    await VisualizeLocationStore.visualizeLocation({ boxId: this.boxId || "" });
    this.shelf = VisualizeLocationStore.block || 1
  }

  gotoBox(boxId: string){
    this.$router.push('/box/' + boxId)
    this.$emit('close')
  }

  get processedBox() {
    if (VisualizeLocationStore.boxs === null) return null;

    type ProcessedBox = {
      boxId: string;
      processedBoxId: string;
      label: string | undefined;
      containBnsN: number;
      boxSize: number; 
    } | null

    const output: ProcessedBox[][][][] = []; //shelf -> row -> drawer -> box
    //init output

    for (let i = 0; i < maxShelf; i++) {
      output.push([]);
      for (let j = 0; j < maxRow; j++) {
        output[i].push([]);
        for (let k = 0; k < maxDrawer; k++) {
          output[i][j].push([]);
          for (let l = 0; l < maxBox; l++)
            output[i][j][k].push(null)
        }
      }
    }

    //fill output
    const mapDrawerNum: Record<string, number> = {
      A: 0,
      B: 1,
      C: 2,
      D: 3,
      E: 4,
    };
    for (const box of VisualizeLocationStore.boxs) {
      output[box.block-1][box.rackRow-1][mapDrawerNum[box.drawer]][box.rackColumn-1] =
        {
          boxId: box.boxId,
          processedBoxId: box.boxId.replace(this.freezerNo || '', ''),
          label: box.label,
          containBnsN: box.containBnsN,
          boxSize: box.boxSize
        }
    }

    return output;
  }

  get freezerNo(): string | null{
    return VisualizeLocationStore.freezerNo
  }

  get shelfData(): {usedBoxN: number; totalBoxN: number}[] | null{
    const processedBox = this.processedBox
    if (!processedBox) return null
    const output: {usedBoxN: number; totalBoxN: number}[] = []
    for(let i=0; i<maxShelf; i++){
      let usedCount = 0
      let totalCount = 0
      for(let j=0; j<maxRow; j++){
        for (let k = 0; k < maxDrawer; k++) {
          for (let l = 0; l < maxBox; l++){
            if(processedBox[i][j][k][l]) usedCount++
            totalCount ++
          }
        }
      }
      output.push({
        usedBoxN: usedCount,
        totalBoxN: totalCount
      })
    }
    return output
  }

}
