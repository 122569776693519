










































import sleep from "sleep-promise";
import { Vue, Component, Prop } from "vue-property-decorator";
import UpdateBiospecimenTypeStore from "../../store/UpdateBiospecimenType";
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class EditBiospecimenTypeModal extends Vue {
  @Prop() readonly onClose: Function | undefined;
  @Prop() readonly biospecimenCode: string | undefined;
  @Prop() readonly biospecimenType: string | undefined;

  inputBiospecimenCode = "";
  inputBiospecimenType = "";
  inputBiospecimenNameFailed = false;
  updateResult = "";

  authenedUserHasPms = authenedUserHasPms

  mounted() {
    this.inputBiospecimenCode = this.biospecimenCode || "";
    this.inputBiospecimenType = this.biospecimenType || "";
  }

  async updateBiospecimenType() {
    this.updateResult = "";
    this.inputBiospecimenNameFailed = false;
    let passValidate = true;
    await sleep(250);

    //trim
    this.inputBiospecimenCode = this.inputBiospecimenCode.trim();
    this.inputBiospecimenType = this.inputBiospecimenType.trim();

    //validate
    if (!this.inputBiospecimenType) {
      passValidate = false;
      this.inputBiospecimenNameFailed = true;
    }

    if (!passValidate) {
      this.updateResult = "Please fill all required data correctly";
      return;
    }

    const result = await UpdateBiospecimenTypeStore.updateBiospecimenType({
      type: this.inputBiospecimenCode,
      name: this.inputBiospecimenType,
    });
    if (result.status === "failure") this.updateResult = result.message;
    else if (result.status === "success") {
      this.$emit("close");
      this.$router
        .push({
          name: "biospecimenTypesUpdated",
          params: { updatedBiospecimenCode: this.biospecimenCode || "" },
        })
        .catch(() => this.$router.go(0));
    }
  }

  openConfirmDelete() {
    this.$buefy.dialog.confirm({
      title: "Deleting Biospecimen Type",
      message:
        "Are you sure you want to <b>delete</b> biospecimen type? This action cannot be undone.",
      confirmText: "Delete",
      type: "is-danger",
      hasIcon: true,
      onConfirm: () => this.deleteBiospecimenType(),
    });
  }

  async deleteBiospecimenType() {
    this.updateResult = "";
    sleep(250);
    const result = await UpdateBiospecimenTypeStore.deleteBiospecimenType({
      type: this.biospecimenCode || "",
    });

    if (result.status === "success") {
      this.$emit("close");
      this.$router
        .push({
          name: "biospecimenTypesDeleted",
        })
        .catch(() => this.$router.go(0));
    } else if (result.status === "failure") {
      this.updateResult = result.message;
    }
  }
}
