









































































































import { Vue, Component } from "vue-property-decorator";
import FetchTubeActionLogsStore from "../../store/FetchTubeActionLogs";
import TubeActionLogsTableSkeleton from "./TubeActionLogsTableSkeleton.vue";

@Component({
  components: { TubeActionLogsTableSkeleton },
})
export default class TubeActionLogs extends Vue {
  inputPerformerIdx: string | null = null;
  inputAction: string | null = null;
  inputData = "";
  inputShowSample = false;
  inputShowBoxLocation = false;
  inputShowProject = false;
  inputShowDisease = false;
  inputShowBiospecimenType = false;
  inputShowPI = false;
  inputShowUser = false;

  actions = ["Generate", "Migrate", "Move", "Take", "Edit", "Delete"];

  mounted() {
    FetchTubeActionLogsStore.fetchTubeActionLogs({ ...this.$route.query });
    FetchTubeActionLogsStore.fetchPreGenData().then(() => {
      //load search logs option from url and render component
      const performerId = this.$route.query.performerId as string | undefined;
      const performerText = this.$route.query.performerText as
        | string
        | undefined;
      const action = this.$route.query.action as string | undefined;
      const showSample = this.$route.query.showSample as string | undefined;
      const showBoxLocation = this.$route.query.showBoxLocation as
        | string
        | undefined;
      const showProject = this.$route.query.showProject as string | undefined;
      const showDisease = this.$route.query.showDisease as string | undefined;
      const showBiospecimenType = this.$route.query.showBiospecimenType as
        | string
        | undefined;
      const showPI = this.$route.query.showPI as string | undefined;
      const showUser = this.$route.query.showUser as string | undefined;
      const data = this.$route.query.data as string | undefined;

      if (performerId) {
        const idx = this.preGenPerformers.findIndex(
          (x) => x._id === performerId
        );
        if (idx >= 0) this.inputPerformerIdx = idx.toString();
      }

      if (performerText) {
        const idx = this.preGenPerformers.findIndex(
          (x) => x.fullName === performerText
        );
        if (idx >= 0) this.inputPerformerIdx = idx.toString();
      }

      if (action) this.inputAction = action;
      if (showSample) this.inputShowSample = true;
      if (showBoxLocation) this.inputShowBoxLocation = true;
      if (showProject) this.inputShowProject = true;
      if (showDisease) this.inputShowDisease = true;
      if (showBiospecimenType) this.inputShowBiospecimenType = true;
      if (showPI) this.inputShowPI = true;
      if (showUser) this.inputShowUser = true;
      if (data) this.inputData = data;
    });
  }

  searchLog() {
    if (!this.preGenPerformers) return;

    this.inputData = this.inputData.trim();

    type Options = {
      showPage: string;
      showPerPage: string;
      sortBy: string;
      orderBy: string;
      performerId?: string;
      performerText?: string;
      action?: string;
      showSample?: string;
      showBoxLocation?: string;
      showProject?: string;
      showDisease?: string;
      showBiospecimenType?: string;
      showPI?: string;
      showUser?: string;
      data?: string;
    };

    const options: Options = {
      showPage: "1",
      showPerPage: "10",
      sortBy: "createdAt",
      orderBy: "-1",
      performerId: undefined,
      performerText: undefined,
      action: undefined,
      showSample: this.inputShowSample ? "1" : undefined,
      showBoxLocation: this.inputShowBoxLocation ? "1" : undefined,
      showProject: this.inputShowProject ? "1" : undefined,
      showDisease: this.inputShowDisease ? "1" : undefined,
      showBiospecimenType: this.inputShowBiospecimenType ? "1" : undefined,
      showPI: this.inputShowPI ? "1" : undefined,
      showUser: this.inputShowUser ? "1" : undefined,
      data: this.inputData ? this.inputData : undefined,
    };

    //filter performer
    if (this.inputPerformerIdx !== null) {
      const idx = +this.inputPerformerIdx;
      if (this.preGenPerformers[idx].isDeleted === false) {
        options.performerId = this.preGenPerformers[idx]._id || "";
      } else {
        options.performerText = this.preGenPerformers[idx].fullName;
      }
    }

    //filter action
    if (this.inputAction !== null) {
      options.action = this.inputAction;
    }

    this.$router
      .push({
        name: "tubeActionLogs",
        query: { ...this.$route.query, ...options },
      })
      .catch(() => {
        this.$router.go(0);
      });
  }

  onShowPerPageSelectChange(showPerPage: string) {
    const newParams = { ...this.$route.query, showPerPage, showPage: "1" };
    this.$router.push({ name: "tubeActionLogs", query: newParams });
  }

  onPerformerIdxChange(v: string) {
    if (v === "Deselect") this.inputPerformerIdx = null;
  }

  onActionSelectChange(v: string) {
    if (v === "Deselect") this.inputAction = null;
  }

  onSort(field: string, order: string) {
    const processedOrder = order === "asc" ? "1" : "-1";
    const newParams = {
      ...this.$route.query,
      sortBy: field,
      orderBy: processedOrder,
    };
    this.$router.push({ name: "tubeActionLogs", query: newParams });
  }

  get defaultSort() {
    const processedOrder = this.$route.query.orderBy === "1" ? "asc" : "desc";
    return [this.$route.query.sortBy, processedOrder];
  }

  get showPage() {
    const showPage = this.$route.query.showPage;
    if (typeof showPage === "string") return parseInt(showPage);
    else return null;
  }

  set showPage(showPage: number | null) {
    const newParams = {
      ...this.$route.query,
      ...{ showPage: String(showPage) },
    };
    this.$router.push({ name: "tubeActionLogs", query: newParams });
  }

  get showPerPage() {
    const showPerPage = this.$route.query.showPerPage;
    if (typeof showPerPage === "string") return parseInt(showPerPage);
    else return null;
  }

  get showFrom() {
    if (this.showPage && this.showPerPage)
      if (this.showTo) return (this.showPage - 1) * this.showPerPage + 1;
      else return 0;
    else return 0;
  }

  get showTo() {
    if (this.showPage && this.showPerPage)
      return (
        (this.showPage - 1) * this.showPerPage + this.tubeActionLogs.length
      );
    else return 0;
  }

  get tubeActionLogs() {
    return FetchTubeActionLogsStore.tubeActionLogs.map((x, i) => {
      if (this.showPage && this.showPerPage)
        return { ...x, index: i + 1 + (this.showPage - 1) * this.showPerPage };
    });
  }

  get tubeActionLogCount() {
    return FetchTubeActionLogsStore.tubeActionLogCount;
  }

  get preGenPerformers() {
    return FetchTubeActionLogsStore.preGenPerformers;
  }

  get loading() {
    return (
      FetchTubeActionLogsStore.loadingPreGenData ||
      FetchTubeActionLogsStore.loadingLogs
    );
  }
}
