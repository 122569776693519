import jsPDF from 'jspdf'
import { format } from "date-fns";

type Label = {
  biobankNo: string;
  patientNo: string;
  admitDate: string;
  disease: {
    code: string;
    name: string;
  };
  biospecimenType: {
    type: string;
    name: string;
  };
}

function formatDate(dateStr: string) {
  return format(new Date(dateStr), "(yyyy-MM-dd)");
}

function drawCell(label: Label, doc: any, x: number, y: number){
  doc.rect(x, y, 34, 7)
  doc.rect(x, y+7, 34, 9)
  doc.setFontSize(9)
  doc.setFontStyle('bold')
  doc.text(label.disease.code + ' ' + label.patientNo, x + 0.5 ,y+3.5)
  doc.setFontSize(6)
  doc.text(label.disease.name, x + 0.5 ,y+6 )

  doc.setFontStyle('normal')
  doc.setFontSize(7)
  doc.setTextColor(200,0,0)
  doc.text(label.biospecimenType.name, x + 0.5, y + 9.5)
  doc.setTextColor(0,0,0)
  doc.setFontStyle('bold')
  doc.text('BN: ' + label.biobankNo, x + 0.5, y + 12.5)
  doc.setFontStyle('normal')
  doc.text(formatDate(label.admitDate), x + 0.5, y + 15)
}

 //todo expand multiple page
 export function previewPrint(labels: Label[]){
  const doc = new jsPDF('p','mm','a4')

  let nowx = 5
  let nowy = 5

  for(let i=0; i<labels.length; i++){
    drawCell(labels[i], doc, nowx, nowy) 
    //go new line
    if ((i+1)%5 === 0 && i !== 0){
      nowx = 5
      nowy += 16
    }
    else{
      nowx += 34
    }
  }

  window.open(doc.output('bloburl'))
}