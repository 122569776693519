




























































































import { Vue, Component } from "vue-property-decorator";
import SampleChart from "../../components/Dashboard/SampleChart.vue";
import ProjectChart from "../../components/Dashboard/ProjectChart.vue";
import DiseaseChart from "../../components/Dashboard/DiseaseChart.vue";
import BiospecimenTypeChart from "../../components/Dashboard/BiospecimenTypeChart.vue";

import ProjectsTable from "../Projects/ProjectsTable.vue";
import BNsTable from "../BNs/BNsTable.vue";
import FetchDashboardStore from "../../store/FetchDashboard";
import SearchLocation from "../../store/SearchLocation";

@Component({
  components: {
    SampleChart,
    ProjectChart,
    DiseaseChart,
    BiospecimenTypeChart,
    ProjectsTable,
    BNsTable,
  },
})
export default class Dashboard extends Vue {
  mounted() {
    FetchDashboardStore.fetchDashboard();
    SearchLocation.RESET_SHOW_COLOUMN_OPTIONS();
  }

  get projectsCount() {
    return FetchDashboardStore.projectsCount;
  }
  get bnsCount() {
    return FetchDashboardStore.bnsCount;
  }
  get locationsCount() {
    return FetchDashboardStore.locationsCount;
  }
  get staffsCount() {
    return FetchDashboardStore.staffsCount;
  }
  get takenBnsCount() {
    return FetchDashboardStore.takenBnsCount;
  }
  get loading() {
    return FetchDashboardStore.loading;
  }

  get bnStat() {
    return FetchDashboardStore.bnStat;
  }
  get lowestBnStatYear() {
    const years = FetchDashboardStore.bnStat?.years ?? [0];
    return Math.min(...years);
  }
  get highestBnStatYear() {
    const years = FetchDashboardStore.bnStat?.years ?? [0];
    return Math.max(...years);
  }
  get projectStat() {
    return FetchDashboardStore.projectStat;
  }
}
