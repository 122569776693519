import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface Staff {
  _id: string;
  name: string;
}

interface Disease {
  code: string;
  name: string;
  _id: string;
}

interface Cell {
  disease: Disease;
  patientNo: string;
  biospecimenType: string;
  biobankNo: string;
  date: string;
}

interface MFetchBox {
  fullBoxNo: string;
  building: string;
  room: string;
  freezerNo: number;
  block: number;
  drawer: string;
  rackRow: number;
  rackColumn: number;
  owner: Staff;
  box: Array<Array<Cell | null>>;
  boxWidth: number;
  boxHeight: number;
  label: string;
}

interface Location {
  box: Array<Array<Cell | null>>;
  fullBoxNo: string;
  owner: Staff;
  building: string;
  room: string;
  freezerNo: number;
  block: number;
  drawer: string;
  rackRow: number;
  rackColumn: number;
  boxWidth: number;
  boxHeight: number;
}

@Module({ name: 'RenderBoxModule', store, dynamic: true, namespaced: true })
class RenderBoxStore extends VuexModule {
  fetchedBox = false
  location: Location | null = null

  @Mutation
  RESET() {
    this.fetchedBox = false
    this.location = null
  }

  @Mutation
  FETCH_BOX(payload: MFetchBox) {
    this.fetchedBox = true
    this.location = payload
  }

  @Action
  async fetchBox(locationId: string) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_BOX
    return axios.post(
      url,
      {
        locationId
      },
      {
        headers: {
          token: loadToken()
        }
      }
    )
      .then(resp => {
        this.FETCH_BOX({ ...resp.data })
      })
      .catch(err => {
        console.log(err.response)
      })
  }
}

export default getModule(RenderBoxStore)
