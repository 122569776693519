










































import { Vue, Component, Prop } from "vue-property-decorator";
import AddBiospecimenStore from "../../store/AddBiospecimen";
import { format } from "date-fns";
import EditBiospecimenTypeModal from './EditBiospecimenTypeModal.vue'
import {Biospecimen} from '../../store/GenerateBN'
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class BiospecimenTypesTable extends Vue {

  authenedUserHasPms = authenedUserHasPms

  mounted() {
    AddBiospecimenStore.fetchBiospecimenTypes();
  }

  openEditBiospecimenTypeModal(row: Biospecimen){
    this.$buefy.modal.open({
      parent: this,
      component: EditBiospecimenTypeModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        biospecimenCode: row.type,
        biospecimenType: row.name
      },
    });
  }

  get biospecimenTypes() {
    return AddBiospecimenStore.biospecimenTypes.map(
      (x,i) => ({
        ...x, index: i+1
      })
    )
  }

  get loading() {
    return AddBiospecimenStore.loading
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd ");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }
}
