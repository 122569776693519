





































import { Vue, Component } from "vue-property-decorator";
import AddStaffStore from '../../store/AddStaff'
import sleep from 'sleep-promise'

@Component
export default class AddStaff extends Vue {
  inputPrename = ''
  inputPrenameFailed = false
  inputFirstname = ''
  inputFirstnameFailed = false
  inputLastname = ''
  inputLastnameFailed = false
  postAddStaffResult = ''

  async uiPostAddStaff(){
    this.postAddStaffResult = ''
    this.inputPrenameFailed = false
    this.inputFirstnameFailed = false
    this.inputLastnameFailed = false
    let passValidate = true
    await sleep(250)

    //trim
    this.inputPrename = this.inputPrename.trim()
    this.inputFirstname = this.inputFirstname.trim()
    this.inputLastname = this.inputLastname.trim()

    //validate
    if(!this.inputPrename){
      passValidate = false
      this.inputPrenameFailed = true
    }
    if(!this.inputFirstname){
      passValidate = false
      this.inputFirstnameFailed = true
    }
    if(!this.inputLastname){
      passValidate = false
      this.inputLastnameFailed = true
    }
    if(!passValidate){
      this.postAddStaffResult = 'Please fill all required data'
      return
    }

    const result = await AddStaffStore.postAddStaff({
      prename: this.inputPrename,
      firstname: this.inputFirstname,
      lastname: this.inputLastname
    })

    if(result.status === 'success'){
      this.$router.push({name: 'staffs', query:{newStaff:'true'}})
    }else{
      this.postAddStaffResult = result.message
    }
  }
}
