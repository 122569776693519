import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import axios from "../utils/CustomAxios";
import { loadToken } from "./Authenticate";
import store from "./index";

interface Staff {
  _id: string;
  name: string;
}

export interface Location {
  id: string;
  projectId: string;
  owner: Staff;
  building: string;
  room: string;
  freezerNo: string;
  block: number;
  drawer: string;
  rackRow: number;
  rackColumn: number;
  dateStr: string;
  countBNs: number;
  boxWidth: number;
  boxHeight: number;
  label: string;
}

@Module({
  name: "FetchLocationsModule",
  store,
  dynamic: true,
  namespaced: true,
})
class FetchLocationsStore extends VuexModule {
  locations: Array<Location> = [];
  loading = false;

  //used on /locations only
  filterFreezer: string | null = null;
  searchingBoxId: string | null = null;

  @Mutation
  RESET() {
    this.locations = [];
  }

  @Mutation
  FETCH_LOCATIONS(payload: Array<Location>) {
    this.locations = payload;
  }

  @Mutation
  SET_LOADING(v: boolean) {
    this.loading = v;
  }

  @Mutation
  SET_FILTER_FREEZER(v: string | null) {
    this.filterFreezer = v;
  }

  @Mutation
  SET_SEARCHING_BOX_ID(v: string | null) {
    this.searchingBoxId = v;
  }

  get filteredLocations() {
    const filteredStep1 =
      this.filterFreezer === null
        ? this.locations
        : this.locations.filter(
            (location) => location.freezerNo === this.filterFreezer
          );

    const trimedAndLowerCasedInput =
      this.searchingBoxId !== null
        ? this.searchingBoxId.trim().toLowerCase()
        : "";
    return this.searchingBoxId === null
      ? filteredStep1
      : filteredStep1.filter((location) =>
          location.id.toLowerCase().includes(trimedAndLowerCasedInput ?? "")
        );
  }

  get uniqueFreezerNos() {
    const uniqueFreezerNosMap: Record<string, boolean> = {};
    for (const loc of this.locations) {
      uniqueFreezerNosMap[loc.freezerNo] = true;
    }
    return Object.keys(uniqueFreezerNosMap);
  }
  //wip use this in locations table and
  //set filterFreezer in parent of locations table

  @Action
  async fetchLocations({
    sortBy,
    orderBy,
  }: {
    sortBy: string;
    orderBy: number;
  }) {
    const url =
      process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_LIST;
    this.SET_LOADING(true);
    return axios
      .get(url, {
        headers: {
          token: loadToken(),
        },
        params: { sortBy, orderBy },
      })
      .then((resp) => {
        this.FETCH_LOCATIONS(resp.data.locations);
        this.SET_LOADING(false);
      })
      .catch((err) => {
        console.log(err);
        this.SET_LOADING(false);
      });
  }
}
export default getModule(FetchLocationsStore);
