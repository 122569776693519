

















































































import { Vue, Component, Prop } from "vue-property-decorator";
import LocationsTable from "./LocationsTable.vue";
import LocationVisualizer from "../../components/LocationVisualizer.vue";
import PrintLocationLabelStore from "../../store/PrintLocationLabel";
import FetchLocationStore from "../../store/FetchLocations";
import SearchBNLocation from "../SearchLocation/SearchBNLocation.vue";

@Component({
  components: { LocationsTable, LocationVisualizer },
})
export default class Locations extends Vue {
  inputSelectFilterFreezer = "ALL";
  searchBoxIdDelayTimer = 0;
  searchBoxIdLoading = false;

  mounted() {
    FetchLocationStore.SET_FILTER_FREEZER(null);
    FetchLocationStore.SET_SEARCHING_BOX_ID(null);
  }

  onFilterFreezerSelectChange(freezerNo: string) {
    if (freezerNo === "ALL") FetchLocationStore.SET_FILTER_FREEZER(null);
    else FetchLocationStore.SET_FILTER_FREEZER(freezerNo);
  }

  onSearchBoxIdChange(searchingBoxId: string) {
    clearTimeout(this.searchBoxIdDelayTimer);
    this.searchBoxIdLoading = true;
    this.searchBoxIdDelayTimer = setTimeout(() => {
      if (searchingBoxId)
        FetchLocationStore.SET_SEARCHING_BOX_ID(searchingBoxId);
      else FetchLocationStore.SET_SEARCHING_BOX_ID(null);
      this.searchBoxIdLoading = false;
    }, 750);
  }

  printLocationLabel() {
    if (this.locsBasketLength === 0) {
      this.$buefy.dialog.alert({
        title: "Error",
        message: "Please select box location",
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
      });
    } else {
      this.$router.push("/printLocationLabel");
    }
  }

  toggleSelectAllBox() {
    //selected all already ?
    if (
      FetchLocationStore.locations.length ===
      PrintLocationLabelStore.locsBasket.length
    ) {
      PrintLocationLabelStore.CLEAR_BASKET();
    } else {
      for (const loc of FetchLocationStore.locations) {
        PrintLocationLabelStore.ADD_TO_BASKET({ loc });
      }
    }
  }

  get locsBasketLength() {
    return PrintLocationLabelStore.locsBasket.length;
  }

  get uniqueFreezerNos() {
    return FetchLocationStore.uniqueFreezerNos;
  }

  get filteredLocationsLength() {
    return FetchLocationStore.filteredLocations.length;
  }

  get locationsLength() {
    return FetchLocationStore.locations.length;
  }
}
