import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface APostAddDisease {
  diseaseCode: string;
  diseaseName: string;
}

export interface Disease {
  code: string;
  name: string;
  dateStr: string;
}

@Module({ name: 'AddDiseaseModule', store, dynamic: true, namespaced: true })
class AddDiseaseStore extends VuexModule {
  diseases: Array<Disease> = []
  loading = false

  @Mutation
  FETCH_DISEASES(payload: Array<Disease>) {
    this.diseases = payload
  }

  @Mutation
  SET_LOADING(v: boolean){
    this.loading = v
  }

  @Action
  async postAddDisease(payload: APostAddDisease) {
    const url = process.env.VUE_APP_API_BASE_URL +
      process.env.VUE_APP_API_DISEASE_ADD
    return axios.post(
      url, {
      ...payload
    },
      {
        headers: { token: loadToken() }
      }
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err?.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to add disease'
          }
      })
  }

  @Action
  async fetchDisease() {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_DISEASE_LIST
    this.SET_LOADING(true)
    return axios.get(
      url,
      {
        headers: {
          token: loadToken()
        }
      }
    )
      .then(resp => {
        this.SET_LOADING(false)
        this.FETCH_DISEASES(resp.data.diseases)
        return resp.data
      })
      .catch(err => {
        this.SET_LOADING(false)
        if (err?.response)
          return err.response.data
        else
          return { status: 'failure', message: 'Failed to get diseases' }
      })
  }
}

export default getModule(AddDiseaseStore)