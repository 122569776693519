
















































































































































import { Vue, Component } from "vue-property-decorator";
import AddLocationStore from "../../store/AddLocation";
import sleep from 'sleep-promise'
import FreezerVisualizerModal, {FreezerVisualizer} from '../../components/FreezerVisualizerModal/FreezerVisualizerModal.vue'

@Component({
  components:{FreezerVisualizerModal}
})
export default class AddLocationOnly extends FreezerVisualizer {
  inputBuilding = "";
  inputBuildingFailed = false
  inputRoom = "";
  inputRoomFailed = false
  inputFreezerNo = "";
  inputFreezerNoFailed = false
  inputBlock: string | null = null;
  inputBlockFailed = false
  inputDrawer: string | null = null;
  inputDrawerFailed = false
  inputRackRow: string | null = null;
  inputRackRowFailed = false
  inputRackColumn: string | null = null;
  inputRackColumnFailed = false
  inputBoxWidth = "9";
  inputBoxWidthFailed = false
  inputBoxHeight = "9";
  inputBoxHeightFailed = false
  inputLabel = ''

  postAddLocationResult = "";

  mounted() {
    AddLocationStore.RESET();
  }

  async uiPostAddLocation() {
    this.postAddLocationResult = "";
    this.inputBuildingFailed = false
    this.inputRoomFailed = false
    this.inputFreezerNoFailed = false
    this.inputBlockFailed = false
    this.inputDrawerFailed = false
    this.inputRackRowFailed = false
    this.inputRackColumnFailed = false
    this.inputBoxWidthFailed = false
    this.inputBoxHeightFailed = false
    let passValidate = true
    await sleep(250)

    //strip
    this.inputBuilding = this.inputBuilding.trim()
    this.inputRoom = this.inputRoom.trim()
    this.inputFreezerNo = this.inputFreezerNo.trim()
    this.inputBoxWidth = this.inputBoxWidth.trim()
    this.inputBoxHeight = this.inputBoxHeight.trim()
    this.inputLabel = this.inputLabel.trim()

    //validate
    if (!this.inputBuilding){
      passValidate = false
      this.inputBuildingFailed = true
    }
    if (!this.inputRoom){
      passValidate = false
      this.inputRoomFailed = true
    }
    if (!this.inputFreezerNo){
      passValidate = false
      this.inputFreezerNoFailed = true
    }
    if (this.inputBlock === null){
      passValidate = false
      this.inputBlockFailed = true
    }
    if (this.inputDrawer === null){
      passValidate = false
      this.inputDrawerFailed = true
    }
    if (this.inputRackRow === null){
      passValidate = false
      this.inputRackRowFailed = true
    }
    if (this.inputRackColumn === null){
      passValidate = false
      this.inputRackColumnFailed = true
    }
    if (isNaN(parseInt(this.inputBoxWidth))){
      passValidate = false
      this.inputBoxWidthFailed = true
    }
    if(isNaN(parseInt(this.inputBoxHeight))){
      passValidate = false
      this.inputBoxHeightFailed = true
    }

    if(!passValidate || this.inputBlock===null || this.inputDrawer===null || this.inputRackRow===null || this.inputRackColumn===null){
      this.postAddLocationResult = "Please fill all required data correctly"
      return
    }

    const result = await AddLocationStore.postAddLocation({
      building: this.inputBuilding,
      room: this.inputRoom,
      block: +this.inputBlock,
      freezerNo: this.inputFreezerNo,
      drawer: this.inputDrawer,
      rackRow: +this.inputRackRow,
      rackColumn: +this.inputRackColumn,
      boxWidth: parseInt(this.inputBoxWidth),
      boxHeight: parseInt(this.inputBoxHeight),
      label: this.inputLabel
    });

    this.postAddLocationResult = result.message;
    if (result.status === "success")
      this.$router.push({ name: "locations", query: { newLocation: "true" } });
  }

  get projects() {
    return AddLocationStore.projects;
  }

  get newLocationId() {
    return AddLocationStore.newLocationId;
  }

  get staffs() {
    return AddLocationStore.staffs;
  }

  get fetchedLocationPreGenData() {
    return AddLocationStore.fetchedLocationPreGenData;
  }
}
