import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'
import {Biospecimen} from './GenerateBN'

@Module({ name: 'AddBiospecimenModule', store, dynamic: true, namespaced: true })
class AddBiospecimenStore extends VuexModule {
  biospecimenTypes: Array<Biospecimen> = []
  loading = false

  @Mutation
  RESET(){
    this.biospecimenTypes = []
  }

  @Mutation
  FETCH_BIOSPECIMENTYPES(payload: Array<Biospecimen>){
    this.biospecimenTypes = payload
  }

  @Mutation
  SET_LOADING(v: boolean) {
    this.loading = v
  }

  @Action
  async fetchBiospecimenTypes(){
    const url = process.env.VUE_APP_API_BASE_URL +
      process.env.VUE_APP_API_BIOSPECIMENTYPE_LIST
    this.SET_LOADING(true)
    return axios.get(
      url,
      {
        headers: {
          token: loadToken()
        },
      }
    )
    .then(resp => {
      this.SET_LOADING(false)
      this.FETCH_BIOSPECIMENTYPES(resp.data.biospecimenTypes)
    })
    .catch(err => {
      this.SET_LOADING(false)
      if (err?.response)
      return err.response.data
      else
        return {
          status: 'failure',
          message: 'Failed to get Biospecimen Type'
        }
    })
  }

  @Action
  async postAddBiospecimenType(payload: {biospecimenCode: string; biospecimenName: string}){
    const url = process.env.VUE_APP_API_BASE_URL +
      process.env.VUE_APP_API_BIOSPECIMENTYPE_ADD
    return axios.post(
      url,
      { ...payload},
      { headers: {token: loadToken()}}
    )
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      if (err?.response)
      return err.response.data
    else
      return {
        status: 'failure',
        message: 'Failed to add Biospecimen Type'
      }
    })
  }
}

export default getModule(AddBiospecimenStore)