import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface AUpdateProject{
  id: string;
  piId: string;
  diseaseId: string;
}

interface ADeleteProject{
  id: string;
}

@Module({ name: 'UpdateProjectModule', store, dynamic: true, namespaced: true })
class UpdateProjectStore extends VuexModule {
  @Action updateProject(payload: AUpdateProject){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_PROJECT_UPDATE
    return axios.put(
      url, {...payload}, { headers: {token: loadToken()}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to update project. Please try again later.'
          }
      })
  }

  @Action deleteProject(payload: ADeleteProject){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_PROJECT_DELETE
    return axios.delete(
      url, { headers: {token: loadToken()}, params: {...payload}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to delete project'
          }
      })
  }
}

export default getModule(UpdateProjectStore)