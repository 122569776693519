import axios from 'axios'
import AuthenticateStore from '../store/Authenticate'

axios.interceptors.response.use(resp=>{
  return resp
}, err=>{
  if (err.response && err.response.status === 401){
    AuthenticateStore.signOut()
  }
  return Promise.reject(err)
})

export default axios