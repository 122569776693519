











































import { Vue, Component } from "vue-property-decorator";
import BasketTableSkeleton from './BasketTableSkeleton.vue'
import PrintBNStore from '../../store/Basket'
import SearchLocationStore from "../../store/SearchLocation";
import sleep from 'sleep-promise'
import {previewPrint} from '../../classes/LabelPrinter'
import {authenedUserHasPms} from '../../utils/Role'

@Component({
  components: {BasketTableSkeleton}
})
export default class PrintBNBasket extends Vue{

  postResult = ''
  authenedUserHasPms = authenedUserHasPms

  clearBasket(){
    PrintBNStore.CLEAR_BASKET()
  }

  uiAssignsTubeFromBasket(){
     if (this.basketLength === 0) {
      this.$buefy.dialog.alert({
        title: "Error",
        message: 'Basket is empty',
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
      })
      return
    }
    this.$router.push("/assignTubes")
  }

  uiMoveTubesFromBasket(){
    if (this.basketLength === 0) {
      this.$buefy.dialog.alert({
        title: "Error",
        message: 'Basket is empty',
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
      })
      return
    }
    this.$router.push("/moveTubes") 
  }

  async uiPostPrintBN(){
    if (this.basketLength === 0) {
      this.$buefy.dialog.alert({
        title: "Error",
        message: 'Basket is empty',
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
      })
      return
    }

    this.postResult = ''
    await sleep(250)
    const result = await PrintBNStore.printBN({
      bns: PrintBNStore.bnsBasket.map(x => x.biobankNo)
    })

    if (result.status === 'success'){
      previewPrint(result.labels)
      PrintBNStore.CLEAR_BASKET()
    }
    else{
      this.postResult = result.message
      if ('notFoundBns' in result){
        this.postResult += ' : '
        result.notFoundBns.map((x: string) => this.postResult += x + ' ')
      }
    }
  }

  get bns(){
    return PrintBNStore.bnsBasket
  }

  get showColumnOptions() {
    return SearchLocationStore.showColumnOptions;
  }

  get basketLength() {
    return PrintBNStore.bnsBasket.length
  }

}
