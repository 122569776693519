


























































import { Vue, Component } from "vue-property-decorator";
import AddProjectStore from "../../store/AddProject";
import sleep from 'sleep-promise'

@Component
export default class AddProjectOnly extends Vue{
  inputProjectId = ''
  inputProjectIdFailed = false
  inputPiId: string | null = null;
  inputPiIdFailed = false
  inputDiseaseId: string | null = null;
  inputDiseaseIdFailed = false
  addProjectResult = ''

  mounted(){
    AddProjectStore.RESET()
    AddProjectStore.fetchPreGenData()
  }

  async uiAddProject(){
    this.addProjectResult = ""
    this.inputProjectIdFailed = false
    this.inputPiIdFailed = false
    this.inputDiseaseIdFailed = false
    let passValidate = true
    await sleep(250)
    //trim
    this.inputProjectId = this.inputProjectId.trim()
    //validate input
    if (this.inputProjectId.length !== 5
      || Number.isNaN(parseInt(this.inputProjectId))
      || parseInt(this.inputProjectId) < 1
    ){
      passValidate = false
      this.inputProjectIdFailed = true
    }
    if (this.inputPiId === null){
      passValidate = false
      this.inputPiIdFailed = true
    }
    if (this.inputDiseaseId === null){
      passValidate = false
      this.inputDiseaseIdFailed = true
    }

    if(!passValidate || this.inputDiseaseId === null){
      this.addProjectResult = "Please fill all required data"
      return 
    }

    //extract disease data

    const result = await AddProjectStore.postAddProject({
      projectId: this.inputProjectId,
      piId: this.inputPiId || "",
      diseaseId: this.inputDiseaseId
    });
    this.addProjectResult = result.message;
    if (result.status === 'success')
      this.$router.push({name : 'projects', query: {newProject: 'true'}});
  }

  get pis(){return AddProjectStore.pis}
  get diseases(){return AddProjectStore.diseases}

}
