


























































































import { Vue, Component } from "vue-property-decorator";
import FetchLocationsStore, { Location } from "../../store/FetchLocations";
import { format } from "date-fns";
import FreezerVisualizerModal, {
  FreezerVisualizer,
} from "../../components/FreezerVisualizerModal/FreezerVisualizerModal.vue";
import FreezerVisualizerModal2 from "../../components/FreezerVisualizerModal2/FreezerVisualizerModal2.vue";
import EditLocationModal from "./EditLocationModal.vue";
import { authenedUserHasPms } from "../../utils/Role";
import PrintLocationLabelStore from "../../store/PrintLocationLabel";

@Component({
  components: { FreezerVisualizerModal, FreezerVisualizerModal2 },
})
export default class Locations extends FreezerVisualizer {
  //visualize box location params
  currentShelf = 0;
  currentDrawer = "";
  currentRow = 0;
  currentBox = 0;
  //edit box location params
  isEditLocationModalOpened = false;
  currentBoxId = "";
  currentBuilding = "";
  currentRoom = "";
  currentLabel = "";

  authenedUserHasPms = authenedUserHasPms;

  async mounted() {
    await FetchLocationsStore.fetchLocations({ sortBy: "id", orderBy: 1 });
  }

  openEditLocationModal(row: Location) {
    this.$buefy.modal.open({
      parent: this,
      component: EditLocationModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        boxId: row.id,
        building: row.building,
        room: row.room,
        label: row.label,
      },
    });
  }

  //version 1
  openFreezerVisualizationAndSetData(
    shelf: number,
    drawer: string,
    row: number,
    box: number
  ) {
    this.currentShelf = shelf;
    this.currentDrawer = drawer;
    this.currentRow = row;
    this.currentBox = box;
    this.openFreezerVisualization();
  }

  //version2
  openFreezerVisualizationAndSetData2(boxId: string) {
    this.$buefy.modal.open({
      parent: this,
      component: FreezerVisualizerModal2,
      hasModalCard: true,
      trapFocus: true,
      props: {
        boxId,
      },
      fullScreen: true,
    });
  }

  toggleAddingToBasket(row: Location) {
    PrintLocationLabelStore.toggleAddingToBasket({ loc: row });
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }

  sortByBuildingRoom(a: Location, b: Location, isAsc: boolean) {
    if (isAsc)
      return (
        (a.building + a.room).toLowerCase() >
        (b.building + b.room).toLowerCase()
      );
    else
      return (
        (a.building + a.room).toLowerCase() <
        (b.building + b.room).toLowerCase()
      );
  }

  get locations() {
    const locsInBasket: Record<string, boolean> = {};
    for (const loc of PrintLocationLabelStore.locsBasket) {
      locsInBasket[loc.id] = true;
    }

    return FetchLocationsStore.filteredLocations.map((x, i) => ({
      ...x,
      index: i + 1,
      isInBasket: locsInBasket[x.id] ? true : false,
    }));
  }

  get loading() {
    return FetchLocationsStore.loading;
  }
}
