






























import { Vue, Component, Prop } from "vue-property-decorator";
import { format } from "date-fns";
import AddStaff, {Staff} from "../../store/AddStaff";
import EditStaffModal from './EditStaffModal.vue'
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class StaffsTable extends Vue {

  authenedUserHasPms = authenedUserHasPms

  mounted() {
    const result = AddStaff.fetchStaffs();
  }

  openEditStaffModal(row: Staff){
    this.$buefy.modal.open({
      parent: this,
      component: EditStaffModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        id: row._id,
        title: row.prename,
        firstname: row.firstname,
        lastname: row.lastname
      },
    });
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }

  sortByFullName(a: Staff, b: Staff, isAsc: boolean) {
    if (isAsc)
      return a.firstname.toLowerCase() + ' ' + a.lastname.toLowerCase() > b.firstname.toLowerCase() + ' ' + b.lastname.toLowerCase() ? 1 : -1
    else 
      return a.firstname.toLowerCase() + ' ' + a.lastname.toLowerCase() < b.firstname.toLowerCase() + ' ' + b.lastname.toLowerCase() ? 1 : -1
  }

  get staffs() {
    return AddStaff.staffs.map(
      (x,i) => ({
        ...x, index : i + 1
      })
    );
  }

  get loading() {
    return AddStaff.loadingFetchStaffs
  }
}
