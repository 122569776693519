






































import { Vue, Component } from "vue-property-decorator";
import { format } from "date-fns";
import { EditerModal } from "../../classes/EditerModal";
import AddDisease, { Disease } from "../../store/AddDisease";
import EditDiseaseModal from "./EditDiseaseModal.vue";
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class DiseasesTable extends Vue {

  authenedUserHasPms = authenedUserHasPms

  mounted() {
    AddDisease.fetchDisease();
  }

  openEditDiseaseModal(row: Disease) {
    this.$buefy.modal.open({
      parent: this,
      component: EditDiseaseModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        diseaseCode: row.code,
        diseaseName: row.name,
      },
    });
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }

  get diseases() {
    return AddDisease.diseases.map((x, i) => ({
      ...x,
      index: i + 1,
    }));
  }

  get loading() {
    return AddDisease.loading;
  }
}
