
































































import sleep from "sleep-promise";
import { Vue, Component, Prop } from "vue-property-decorator";
import AddProjectStore from "../../store/AddProject";
import UpdateProjectStore from "../../store/UpdateProject";
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class EditProjectModal extends Vue {
  @Prop() readonly projectId: string | undefined;
  @Prop() readonly piId: string | undefined;
  @Prop() readonly diseaseId: string | undefined;

  updateResult = "";
  inputPiId = "";
  inputPiIdFailed = false;
  inputDiseaseId = "";
  inputDiseaseIdFailed = false;

  authenedUserHasPms = authenedUserHasPms

  async mounted() {
    AddProjectStore.RESET();
    await AddProjectStore.fetchPreGenData();
    this.inputPiId = this.piId || "";
    this.inputDiseaseId = this.diseaseId || "";
  }

  async updateProject() {
    this.updateResult = "";
    sleep(250);
    this.inputPiIdFailed = false;
    this.inputDiseaseIdFailed = false;
    let passValidate = true;
    if (!this.inputPiId) {
      passValidate = false;
      this.inputPiIdFailed = true;
    }
    if (!this.inputDiseaseId) {
      passValidate = false;
      this.inputDiseaseIdFailed = true;
    }
    if (!passValidate) {
      this.updateResult = "Please fill all required data correctly";
      return;
    }

    const result = await UpdateProjectStore.updateProject({
      id: this.projectId || "",
      piId: this.inputPiId,
      diseaseId: this.inputDiseaseId,
    });
    if (result.status === "failure") this.updateResult = result.message;
    else if (result.status === "success") {
      this.$emit('close')
      this.$router
        .push({
          name: "projectsUpdated",
          params: { updatedProject: this.projectId || "" },
        })
        .catch(() => this.$router.go(0));
    }
  }

  get pis() {
    return AddProjectStore.pis;
  }
  get diseases() {
    return AddProjectStore.diseases;
  }

  openConfirmDelete() {
    this.$buefy.dialog.confirm({
      title: "Deleting Project",
      message:
        "Are you sure you want to <b>delete</b> project? This action cannot be undone.",
      confirmText: "Delete",
      type: "is-danger",
      hasIcon: true,
      onConfirm: () => this.deleteProject(),
    });
  }

  async deleteProject() {
    this.updateResult = "";
    sleep(250);
    const result = await UpdateProjectStore.deleteProject({
      id: this.projectId || "",
    });

    if (result.status === "success") {
      this.$emit('close')
      this.$router
        .push({
          name: "projectsDeleted",
        })
        .catch(() => this.$router.go(0));
    } else if (result.status === "failure") {
      this.updateResult = result.message;
    }
  }
}
