





























import { Vue, Component, Prop } from "vue-property-decorator";
import TakeTubeStore from "../../store/TakeTube";

@Component
export default class TubeActionModal extends Vue{
  @Prop(String) readonly biobankNo: string|undefined
  @Prop(String) readonly patientBN: string|undefined
  @Prop(String) readonly biospecimenType: string|undefined
  @Prop(String) readonly date: string|undefined
  @Prop(String) readonly locationId: string|undefined
  @Prop(Function) readonly onClose: Function|undefined

  uiConfirmTakeTube() {

    this.$buefy.dialog.confirm({
      title: "Taking Sample",
      message: "Are you sure you want to take this sample from the box?",
      confirmText: "Take samle",
      type: "is-primary",
      hasIcon: true,
      onConfirm: async () => {
        const result = await TakeTubeStore.postTakeTube({
          locationId: this.locationId || '',
          biobankNo: this.biobankNo || '',
        });
        if (result.status === "success") {
          this.$router.push({
            name: "box",
            query: { takenBiobankNo: this.biobankNo },
          });
        } else {
          
          this.$buefy.dialog.alert({
            title: 'Error',
            message: result.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
          })

        }
      },
    });
  }

  uiNavigateToMoveTube() {
    this.$router.push({name: 'moveTube', params: {
      oldLocationId: this.locationId || "",
      biobankNo: this.biobankNo || ""
    }})
  }
}
