import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface AUpdateBN{
  biobankNo: string;
  admitDate: Date;
  migrate: boolean;
}

interface ADeleteBN{
  biobankNo: string;
}

@Module({ name: 'UpdateBNModule', store, dynamic: true, namespaced: true})
class UpdateBNStore extends VuexModule{
  @Action updateBN(payload: AUpdateBN){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_UPDATE
    return axios.put(
      url, {...payload}, { headers: {token: loadToken()}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to update sample. Please try again later.'
          }
      })
  }

  @Action deleteBN(payload: ADeleteBN){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_DELETE
    return axios.delete(
      url, { headers: {token: loadToken()}, params: {...payload}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to delete sample'
          }
      })
  }
}

export default getModule(UpdateBNStore)