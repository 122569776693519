import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

export interface UserAccount {
  username: string;
  prename: string;
  firstname: string;
  lastname: string;
  roleId: string;
  roleName: string;
  createdAt: string;
}

@Module({ name: 'FetchUserAccountsModule', store, dynamic: true, namespaced: true })
class FetchUserAccountsStore extends VuexModule {
  userAccounts: Array<UserAccount> = []
  loading = false

  @Mutation
  FETCH_USER_ACCOUNTS(payload: Array<UserAccount>) {
    this.userAccounts = payload
  }

  @Mutation
  SET_LOADING(v: boolean) {
    this.loading = v
  }

  @Action
  async fetchUserAccounts() {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_USER_LIST
    this.SET_LOADING(true)
    return axios.get(
      url, {
      headers: {token: loadToken()}
    }
    )
      .then(resp => {
        this.SET_LOADING(false)
        this.FETCH_USER_ACCOUNTS(resp.data.users)
        return resp.data
      })
      .catch(err => {
        this.SET_LOADING(false)
        console.log(err)
      })
  }
}

export default getModule(FetchUserAccountsStore)