import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface Disease {
  code: string;
  name: string;
  _id: string;
}

interface Staff {
  _id: string;
  name: string;
}

interface Project {
  id: string;
  disease: Disease;
  pi: string;
  countBNs: number;
  dateStr: string;
}
interface MFetchPreGenData {
  projects: Array<Project>;
  staffs: Array<Staff>;
}

interface FPostAddLocation {
  building: string;
  room: string;
  freezerNo: string;
  block: number;
  drawer: string;
  rackRow: number;
  rackColumn: number | null;
  boxWidth: number;
  boxHeight: number;
  label: string;
}

interface APostAddTubes {
  biobankNos: Array<string>;
  locationId: string;
}

@Module({ name: 'AddLocationModule', store, dynamic: true, namespaced: true })
class AddLocationStore extends VuexModule {
  projects: Array<Project> = []
  staffs: Array<Staff> = []
  fetchedLocationPreGenData = false
  newLocationId: string | null = null

  @Mutation
  RESET() {
    this.projects = []
    this.staffs = []
    this.fetchedLocationPreGenData = false
    this.newLocationId = null
  }

  @Mutation
  FETCH_PRE_GEN_DATA(payload: MFetchPreGenData) {
    this.projects = payload.projects
    this.staffs = payload.staffs
  }

  @Mutation
  FETCH_LOCATION_PRE_GEN_DATA(newLocationId: string) {
    this.fetchedLocationPreGenData = true
    this.newLocationId = newLocationId
  }

  @Mutation
  SET_FETCHED_LOCATION_PRE_GEN_DATA(payload: boolean) {
    this.fetchedLocationPreGenData = payload
  }

  @Action
  async fetchPreGenData() {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_GETPREGENDATA
    return axios.get(
      url,
      {
        headers: {
          token: loadToken()
        }
      })
      .then(resp => {
        this.FETCH_PRE_GEN_DATA({ projects: resp.data.projects, staffs: resp.data.staffs })
      })
      .catch(err => {
        console.log(err)
      })
  }

  @Action
  async fetchLocationPreGenData(projectId: string) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_GETLOCATIONPREGENDATA
    return axios.post(
      url
      , {
        projectId,
      }, {
      headers: {
        token: loadToken()
      }
    })
      .then(resp => {
        this.FETCH_LOCATION_PRE_GEN_DATA(resp.data.locationId)
      })
      .catch(err => {
        this.SET_FETCHED_LOCATION_PRE_GEN_DATA(false)
        if (err?.response)
          return err.response.data
        return {
          status: 'failure',
          message: 'Failed to generated Box ID'
        }
      })
  }

  @Action
  async postAddLocation(payload: FPostAddLocation) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_ADD
    return axios.post(
      url, {
      ...payload
    }, {
      headers: {
        token: loadToken()
      }
    }
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err?.response)
          return err.response.data
        return {
          status: 'failure',
          message: 'Failed to create new location'
        }
      })

  }

  @Action
  async postAddTube({ biobankNo, locationId }: { biobankNo: string; locationId: string }) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_ADDTUBE
    return axios.post(
      url, {
      biobankNo, locationId
    }, {
      headers: {
        token: loadToken()
      }
    }
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response) {
          return err.response.data
        }
        else {
          return { status: 'failure', message: 'Failed to assign sample to location' }
        }
      })
  }

  @Action
  async postAddTubes(payload: APostAddTubes) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_ADDTUBES
    return axios.post(
      url, { ...payload },
      { headers: { token: loadToken() } }
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response) {
          return err.response.data
        }
        else {
          return { status: 'failure', message: 'Failed to assign samples to location' }
        }
      })

  }
}

export default getModule(AddLocationStore)
