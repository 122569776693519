import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface AUpdateBioSpecimenType{
  type: string;
  name: string;
}

interface ADeleteBiospecimenType{
  type: string;
}

@Module({ name: 'UpdateBiospecimenTypeModule', store, dynamic: true, namespaced: true })
class UpdateBiospecimenTypeStore extends VuexModule {
  @Action updateBiospecimenType(payload: AUpdateBioSpecimenType){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BIOSPECIMENTYPE_UPDATE
    return axios.put(
      url, {...payload}, { headers: {token: loadToken()}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to update biospecimen type. Please try again later.'
          }
      })
  }

  @Action deleteBiospecimenType(payload: ADeleteBiospecimenType){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BIOSPECIMENTYPE_DELETE
    return axios.delete(
      url, { headers: {token: loadToken()}, params: {...payload}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to delete biospecimen type'
          }
      })
  }
}

export default getModule(UpdateBiospecimenTypeStore)