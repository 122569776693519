import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import axios from "../utils/CustomAxios";
import { loadToken } from "./Authenticate";
import store from "./index";

type BNStat = {
  years: number[];
  collected: number[];
  taken: number[];
};

type ProjectStat = {
  projectId: string;
  owner: string;
  bnsCount: number;
}[];

interface MFetchDashboard {
  projectsCount: number | null;
  bnsCount: number | null;
  takenBnsCount: number | null;
  locationsCount: number | null;
  staffsCount: number | null;
  bnStat: BNStat | null;
  projectStat: ProjectStat;
}

@Module({
  name: "FetchDashboardModule",
  store,
  dynamic: true,
  namespaced: true,
})
class FetchDashboardStore extends VuexModule {
  projectsCount: number | null = null;
  bnsCount: number | null = null;
  takenBnsCount: number | null = null;
  locationsCount: number | null = null;
  staffsCount: number | null = null;
  bnStat: BNStat | null = null;
  projectStat: ProjectStat = [];
  loading = false;

  @Mutation
  FETCH_DASHBOARD({
    projectsCount,
    bnsCount,
    locationsCount,
    staffsCount,
    takenBnsCount,
    bnStat,
    projectStat,
  }: MFetchDashboard) {
    this.projectsCount = projectsCount;
    this.bnsCount = bnsCount;
    this.locationsCount = locationsCount;
    this.staffsCount = staffsCount;
    this.takenBnsCount = takenBnsCount;
    this.bnStat = bnStat;
    this.projectStat = projectStat;
  }

  @Mutation
  SET_LOADING(loading: boolean) {
    this.loading = loading;
  }

  @Action
  async fetchDashboard() {
    const url =
      process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_DASHBOARD_GET;
    this.SET_LOADING(true);
    return axios
      .get(url, {
        headers: {
          token: loadToken(),
        },
      })
      .then((resp) => {
        this.FETCH_DASHBOARD(resp.data);
        this.SET_LOADING(false);
      })
      .catch((err) => {
        this.SET_LOADING(false);
        console.log(err);
      });
  }
}

export default getModule(FetchDashboardStore);
