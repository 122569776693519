


























































































































































import { Vue, Component } from "vue-property-decorator";
import RenderBoxStore from "../../store/RenderBox";
import TubeActionModal from "./TubeActionModal.vue";
import TubeModal from "../../components/TubeModal.vue";
import MoveTubeStore from "../../store/MoveTube";
// import FreezerVisualizerModal, {FreezerVisualizer} from '../../components/FreezerVisualizerModal/FreezerVisualizerModal.vue'
import FreezerVisualizerModal2 from '../../components/FreezerVisualizerModal2/FreezerVisualizerModal2.vue'

interface MoveTube {
  startDrag: boolean;
  biobankNo: string | null;
}

@Component({
  components: { TubeActionModal },
})
export default class Box extends Vue {
  postTakeTubeResult = "";

  moveTube: MoveTube = {
    startDrag: false,
    biobankNo: null,
  };

  hoveringPatientBN: string | null = null

  mounted() {
    RenderBoxStore.RESET();
    RenderBoxStore.fetchBox(this.locationId);
  }

  uiOpenTubeModal(biobankNo: string) {
    this.$buefy.modal.open({
      parent: this,
      component: TubeModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        biobankNo
      },
    });
  }

  startDrag(evt: any, row: number, column: number, biobankNo: string) {
    this.moveTube.startDrag = true;
    this.moveTube.biobankNo = biobankNo;
  }

  endDrag(evt: any) {
    this.moveTube.startDrag = false;
    //dont set biobankNo to null because ondrop will not work
  }

  openFreezerVisualization(){
     this.$buefy.modal.open({
      parent: this,
      component: FreezerVisualizerModal2,
      hasModalCard: true,
      trapFocus: true,
      props: {
        boxId: this.location?.fullBoxNo
      },
      fullScreen: true
    })
  }

  async onDrop(evt: any, row: number, column: number) {
    if (!this.moveTube.biobankNo || !this.location) return;
    const result = await MoveTubeStore.postMoveTube({
      biobankNo: this.moveTube.biobankNo,
      oldLocationId: this.location.fullBoxNo,
      newLocationId: this.location.fullBoxNo,
      row,
      column,
    });

    if (result.status === "success") {
      this.$router.push({
        name: "boxNewBN",
        params: {
          locationId: this.location.fullBoxNo,
          newBN: this.moveTube.biobankNo,
          row: row.toString(), column: column.toString()
        },
      });
    }
    else if (result.status === 'failure'){
      this.$buefy.dialog.alert({
            title: "Error",
            message: result.message,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
          });
    }

    this.moveTube.startDrag = false;
    this.moveTube.biobankNo = null;
  }

  get computedBox() {
    if (!this.location) return [];

    const computedBox: any[] = [];
    const patientToColor: Record<string, string> = {};
    const cycleColors: string[] = [
      '#6666FF', '#B266FF', '#FF66FF', '#FF66B2', '#FF6666', '#FFB266',
      '#0000FF', '#7F00FF', '#FF00FF', '#FF007F', '#FF0000', '#FF8000',
      '#000099', '#4C0099', '#990099', '#99004C', '#990000', '#994C00',
      '#999900', '#4C9900', '#009900', '#00994C', '#009999', '#004C99'
    ];

    //init 2d empty array
    for (let i = 0; i < this.location.boxHeight; i++) {
      computedBox.push([]);
      for (let j = 0; j < this.location.boxWidth; j++) {
        const patientNo = this.location.box[i][j]?.patientNo;
        const diseaseCode = this.location.box[i][j]?.disease.code
        if (patientNo && diseaseCode) patientToColor[diseaseCode + patientNo] = "";
        computedBox[i].push(Object.assign({}, this.location.box[i][j]));
      }
    }

    //populate color to patient no
    let cycleI = 0;
    for (const patientNo of Object.keys(patientToColor)) {
      patientToColor[patientNo] = cycleColors[cycleI];
      cycleI = (cycleI + 1) % cycleColors.length;
    }

    //assign color
    for (let i = 0; i < this.location.boxHeight; i++) {
      for (let j = 0; j < this.location.boxWidth; j++) {
        //set color
        let bgColor = ""
        const patientNo = this.location.box[i][j]?.patientNo;
        const diseaseCode = this.location.box[i][j]?.disease.code
        if (patientNo && diseaseCode) {
          bgColor = patientToColor[diseaseCode + patientNo];
        }

        computedBox[i][j] = Object.assign({}, computedBox[i][j], {
          style: `background-color: ${bgColor}; cursor: pointer;`,
        });
      }
    }

    const animatedStyle =
      "animate__animated animate__headShake animate__slower animate__infinite";

    //show simple box , no highlight
    if (this.$route.name === "box") return computedBox;
    //show highlight single cell
    else if (this.showGeneratedBN || this.showSearchBN) {

      for (let i = 0; i < this.location.boxHeight; i++) {
        for (let j = 0; j < this.location.boxWidth; j++) {
          let style = "";

          //check if newBN is valid before checking match row & col
          if(!(!this.newBN || !this.newBN.row || !this.newBN.column))
            if (i === this.newBN.row - 1 && j === this.newBN.column - 1)
              style = animatedStyle;
            else style = "opacity-50";

          computedBox[i][j] = Object.assign({}, computedBox[i][j], {
            classStyle: style,
          });
        }
      }
      return computedBox;
      //show highlight multiple cells
    } else if (this.showGeneratedBNs) {
      for (let i = 0; i < this.location.boxHeight; i++) {
        for (let j = 0; j < this.location.boxWidth; j++) {
          let style = "";

          for (const location of this.showGeneratedBNsLocations) {
            if (location.x - 1 === j && location.y - 1 === i) {
              style = animatedStyle;
              break;
            } else style = "opacity-50";
          }

          computedBox[i][j] = Object.assign({}, computedBox[i][j], {
            classStyle: style,
          });
        }
      }
    }
    return computedBox;
  }

  //show new generatedBN mode
  get showGeneratedBN(): boolean {
    return "newBN" in this.$route.params;
  }

  get showSearchBN(): boolean {
    return this.$route.name === "boxSearchBN";
  }

  get showGeneratedBNs(): boolean {
    return this.$route.name === "boxNewMultipleBNs";
  }

  get showGeneratedBNsLocations(): Array<any> {
    if (this.showGeneratedBNs) {
      const locations = [];
      const locationXs = JSON.parse(this.$route.params.locationXs);
      const locationYs = JSON.parse(this.$route.params.locationYs);
      for (const i in locationXs)
        locations.push({ x: locationXs[i], y: locationYs[i] });
      return locations;
    } else return [];
  }

  get showGeneratedUnassignedBNs(): Array<string> {
    if (this.showGeneratedBNs) {
      return JSON.parse(this.$route.params.unassignedBNs);
    } else return [];
  }

  get newBN() {
    if (this.showGeneratedBN)
      return {
        locationId: this.locationId,
        biobankNo: this.$route.params.newBN,
        row: parseInt(this.$route.params.row),
        column: parseInt(this.$route.params.column),
      };
    else if (this.showSearchBN && this.location) {
      const biobankNo = this.$route.params.searchingBN;
      const locationId = this.$route.params.locationId;
      let row = null;
      let column = null;
      let found = false;
      //search for row and column
      for (let i = 0; i < this.location.boxHeight; i++) {
        for (let j = 0; j < this.location.boxWidth; j++) {
          if (this.location.box[i][j]?.biobankNo === biobankNo) {
            found = true;
            row = i + 1;
            column = j + 1;
            break;
          }
        }
        if (found) break;
      }
      //return
      return {
        locationId,
        biobankNo,
        row,
        column,
      };
    } else return null;
  }

  get locationId() {
    return this.$route.params.locationId;
  }

  get fetchedBox() {
    return RenderBoxStore.fetchedBox;
  }

  get location() {
    return RenderBoxStore.location;
  }

  get actionWord() {
    if (this.$route.query.actionWord)
      return this.$route.query.actionWord
    else 
      return 'Assign'
  }
}
