






































import { Vue, Component, Prop } from "vue-property-decorator";
import ProjectsTable from "./ProjectsTable.vue";

@Component({
  components: { ProjectsTable },
})
export default class Projects extends Vue {}
