import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'
import {UserAccount} from './FetchUserAccounts'

interface Extended extends UserAccount{
  newPassword?: string;
  confirmNewPassword?: string;
}

type AUpdateUserAccount = Omit<Extended, 'createdAt' | 'roleName'>
interface ADeleteUserAccount {
  username: string;
}

@Module({ name: 'UpdateUserAccountModule', store, dynamic: true, namespaced: true })
class UpdateUserAccountStore extends VuexModule {
  @Action updateUserAccount(payload: AUpdateUserAccount){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_USER_UPDATE
    return axios.put(
      url, {...payload}, { headers: {token: loadToken()}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to update user'
          }
      })
  }

  @Action deleteUserAccount(payload: ADeleteUserAccount){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_USER_DELETE
    return axios.delete(
      url, { headers: {token: loadToken()}, params: {...payload}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to delete user'
          }
      })
  }
}

export default getModule(UpdateUserAccountStore)