


























































































































































































import { Vue, Component } from "vue-property-decorator";
import AddLocationStore from "../../store/AddLocation";
import FetchLocationsStore from "../../store/FetchLocations";
import GenerateBNStore from '../../store/GenerateBN'
import GenerateBNSteps from "../../components/GenerateBNSteps.vue";
import FreezerVisualizerModal, {FreezerVisualizer} from '../../components/FreezerVisualizerModal/FreezerVisualizerModal.vue'
import sleep from 'sleep-promise'

@Component({
  components: { GenerateBNSteps, FreezerVisualizerModal },
})
export default class AddLocation extends FreezerVisualizer {
  inputSelectExistingBox: string | null = null;
  inputBuilding = "";
  inputBuildingFailed = false
  inputRoom = "";
  inputRoomFailed = false
  inputFreezerNo = "";
  inputFreezerNoFailed = false
  inputBlock: string | null = null;
  inputBlockFailed = false
  inputDrawer: string | null = null;
  inputDrawerFailed = false
  inputRackRow: string | null = null;
  inputRackRowFailed = false
  inputRackColumn: string | null = null;
  inputRackColumnFailed = false
  inputBoxWidth = "9";
  inputBoxWidthFailed = false
  inputBoxHeight = "9";
  inputBoxHeightFailed = false
  inputLabel = ''
  postResult = "";

  generateBNStep = 1;

  async mounted() {
    //steps animation
    setTimeout(() => (this.generateBNStep = 2), 50);

    FetchLocationsStore.RESET();
    FetchLocationsStore.fetchLocations({ sortBy: "id", orderBy: 1 });
  }

  async performAddTubeOrAddTubes(locationId: string) {
    this.postResult = "";
    //add single tube
    if (!this.addMultipleTubes) {
      const result = await AddLocationStore.postAddTube({
        biobankNo: this.$route.params.bn,
        locationId,
      });

      if (result.status === "success")
        this.$router.push(
          `/box/${locationId}/${this.$route.params.bn}/${result.tubeRow}/${result.tubeColumn}`
        );
      else {
        this.postResult = result.message;
        this.inputSelectExistingBox = null;
      }
      //add multiple tubes
    } else {
      const result = await AddLocationStore.postAddTubes({
        biobankNos: this.biobankNos || [],
        locationId,
      });

      if (result.status === "success") {
        const locationXs: Array<string> = [];
        const locationYs: Array<string> = [];
        const unassignedBNs: Array<string> = [];

        for (const bn of result.bns) {
          if (bn.location) {
            locationXs.push(bn.location.foundColumn);
            locationYs.push(bn.location.foundRow);
          } else {
            unassignedBNs.push(bn.biobankNo);
          }
        }
        this.$router.push({
          name: "boxNewMultipleBNs",
          params: {
            locationId,
            locationXs: JSON.stringify(locationXs),
            locationYs: JSON.stringify(locationYs),
            unassignedBNs: JSON.stringify(unassignedBNs),
          },
        });
      } else {
        this.postResult = result.message;
        this.inputSelectExistingBox = null;
      }
    }
  }

  async onLocationIdSelectChange(locationId: string) {
    this.postResult = "";
    await this.performAddTubeOrAddTubes(locationId);
  }

  async uiPostAddLocation() {
    this.postResult = "";
    this.inputBuildingFailed = false
    this.inputRoomFailed = false
    this.inputFreezerNoFailed = false
    this.inputBlockFailed = false
    this.inputDrawerFailed = false
    this.inputRackRowFailed = false
    this.inputRackColumnFailed = false
    this.inputBoxWidthFailed = false
    this.inputBoxHeightFailed = false
    let passValidate = true
    await sleep(250) 

    //strip
    this.inputFreezerNo = this.inputFreezerNo.trim()
    this.inputBuilding = this.inputBuilding.trim()
    this.inputRoom = this.inputRoom.trim()
    this.inputBoxWidth = this.inputBoxWidth.trim()
    this.inputBoxHeight = this.inputBoxHeight.trim()
    this.inputLabel = this.inputLabel.trim()

    //validate
    if (!this.inputBuilding){
      passValidate = false
      this.inputBuildingFailed = true
    }
    if (!this.inputRoom){
      passValidate = false
      this.inputRoomFailed = true
    }
    if (!this.inputFreezerNo){
      passValidate = false
      this.inputFreezerNoFailed = true
    }
    if (this.inputBlock === null){
      passValidate = false
      this.inputBlockFailed = true
    }
    if (this.inputDrawer === null){
      passValidate = false
      this.inputDrawerFailed = true
    }
    if (this.inputRackRow === null){
      passValidate = false
      this.inputRackRowFailed = true
    }
    if (this.inputRackColumn === null){
      passValidate = false
      this.inputRackColumnFailed = true
    }

    if (isNaN(parseInt(this.inputBoxWidth)) || parseInt(this.inputBoxWidth) <= 0){
      passValidate = false
      this.inputBoxWidthFailed = true
    }
    if(isNaN(parseInt(this.inputBoxHeight)) || parseInt(this.inputBoxHeight) <= 0){
      passValidate = false
      this.inputBoxHeightFailed = true
    }

    if(!passValidate || this.inputBlock===null || this.inputDrawer===null || this.inputRackRow===null || this.inputRackColumn===null){
      this.postResult = "Please fill all required data correctly"
      return
    }

    const result1 = await AddLocationStore.postAddLocation({
      building: this.inputBuilding,
      room: this.inputRoom,
      block: +this.inputBlock,
      freezerNo: this.inputFreezerNo,
      drawer: this.inputDrawer,
      rackRow: +this.inputRackRow,
      rackColumn: +this.inputRackColumn,
      boxWidth: parseInt(this.inputBoxWidth),
      boxHeight: parseInt(this.inputBoxHeight),
      label: this.inputLabel
    });

    if (result1.status !== "success") {
      this.postResult = result1.message;
      return;
    }

    // assign tube to new created location
    await this.performAddTubeOrAddTubes(this.newLocationId || "");
  }

  async undoGeneration(){
    const bns = []

    // get bn both single generate or multiple generate case
    if (!this.addMultipleTubes){
      bns.push(this.$route.params.bn)
    }
    else{
      const bnsFromQuery = this.biobankNos
      if(bnsFromQuery){
        bns.push(...bnsFromQuery)
      }
    }

    //call api
    const result = await GenerateBNStore.undoGeneration({bns})
    if (result.status === 'success'){
      this.$router.go(-1)
    }
    else if (result.status === 'failure'){
       this.$buefy.dialog.alert({
                    title: 'Error',
                    message: result.message,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
      })
    }
  }

  get addMultipleTubes() {
    return (
      this.$route.params.tubeNoLowerBound && this.$route.params.tubeNoUpperBound
    );
  }

  get biobankNos(): Array<string> | null {
    if (this.$route.params.biobankNosStr)
      return JSON.parse(this.$route.params.biobankNosStr);
    else return null;
  }

  get projects() {
    return AddLocationStore.projects;
  }

  get fetchedLocationPreGenData() {
    return AddLocationStore.fetchedLocationPreGenData;
  }

  get newLocationId(): string {
    return `${this.inputFreezerNo}${this.inputBlock}${this.inputDrawer}${this.inputRackRow}${this.inputRackColumn}`
  }

  get staffs() {
    return AddLocationStore.staffs;
  }

  get locations() {
    return FetchLocationsStore.locations;
  }
}
