








































































































import { Vue, Component } from "vue-property-decorator";
import PrintLocationLabelStore from "../../store/PrintLocationLabel";
import QrcodeVue from "qrcode.vue";

@Component({
  components: { QrcodeVue },
})
export default class PrintLocationLabel extends Vue {
  print() {
    window.print();
  }

  get locs() {
    return PrintLocationLabelStore.locsBasket;
  }

  get baseUrl() {
    return process.env.VUE_APP_BASE_URL + "/box/";
  }
}
