






































import { Vue, Component } from "vue-property-decorator";
import DiseasesTable from "./DiseasesTable.vue";

@Component({
  components: { DiseasesTable },
})
export default class Diseases extends Vue {}
