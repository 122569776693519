



































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { format } from "date-fns";
import { BN } from "../../store/FetchBNs";
import TubeModal from "../../components/TubeModal.vue";
import SearchLocationStore from "../../store/SearchLocation";
import Basket from '../../store/Basket'
import EditBNModal from "./EditBNModal.vue";
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class BNsTableSkeleton extends Vue {
  @Prop() readonly BNs: BN[] | undefined;
  @Prop() readonly defaultSort: Array<string> | undefined;
  @Prop() readonly onSort: Function | undefined;
  @Prop() readonly isSortable: boolean | undefined;
  @Prop() readonly hideColumnEdit: boolean | undefined
  @Prop() readonly hideColumnBasket: boolean | undefined

  authenedUserHasPms = authenedUserHasPms

  openEditBNModal(row: BN) {
    this.$buefy.modal.open({
      parent: this,
      component: EditBNModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        biobankNo: row.biobankNo,
        admitDateStr: row.admitDateStr,
        migrate: row.migrate,
      },
    });
  }

  uiOpenTubeModal(biobankNo: string) {
    this.$buefy.modal.open({
      parent: this,
      component: TubeModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        biobankNo,
      },
    });
  }

  toggleAddingToBasket(row: BN) {
    Basket.toggleAddingToBasket({bn: row})
  }

  get enableSort() {
    return this.isSortable ?? true;
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd - HH:mm:ss");
  }

  get showColumnOptions() {
    return SearchLocationStore.showColumnOptions;
  }
}
