


















import { Vue, Component } from "vue-property-decorator";
import BNsTable from './BNsTable.vue'

@Component({
  components: {BNsTable}
})
export default class BNs extends Vue {
}
