


























































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import SearchLocationStore from '../../store/SearchLocation'
import PrintBNStore from '../../store/Basket'
import { BN } from "../../store/FetchBNs";
import { format } from "date-fns";

@Component
export default class BasketTableSkeleton extends Vue {
  @Prop() readonly BNs: BN[] | undefined;
  @Prop() readonly defaultSort: Array<string> | undefined;

  page = 1
  perPage = 25

  get showColumnOptions(){
    return SearchLocationStore.showColumnOptions
  }

  get enableSort(){
    return true
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }

  sortAdmitDate(a: BN, b: BN, isAsc: boolean){
    if(isAsc)
      return new Date(a.admitDateStr).getTime() - new Date(b.admitDateStr).getTime()
    else
      return new Date(b.admitDateStr).getTime() - new Date(a.admitDateStr).getTime()
  }

  sortRecordDate(a: BN, b: BN, isAsc: boolean){
    if(isAsc)
      return new Date(a.dateStr).getTime() - new Date(b.dateStr).getTime()
    else
      return new Date(b.dateStr).getTime() - new Date(a.dateStr).getTime()
  }

  deleteFromBasket(biobankNo: string){
    PrintBNStore.DELETE_FROM_BASKET({biobankNo})
    this.$buefy.snackbar.open({
      message: 'BN : ' + biobankNo + ' has been deleted',
      queue: false
    })
  }

  onShowPerPageSelectChange(showPerPage: string) {
    this.perPage = parseInt(showPerPage)
  }

  get paginatedBNs() {
    if (this.BNs) {
      const startIndex = (this.page - 1) * this.perPage
      const endIndex = startIndex + this.perPage
      return this.BNs.slice( startIndex, endIndex)
        .map( (x, i) => Object.assign(x, {index: i + 1 + startIndex}))
    } else {
      return []
    }
  }

  get showFrom() {
    if (this.showTo) return (this.page - 1) * this.perPage + 1;
      else return 0;
  }

  get showTo() {
    if (this.BNs)
      return Math.min(
        (this.page - 1) * this.perPage + this.perPage,
        this.BNs.length
      )
    else 
      return 0
  }
}
