


































































































import { Vue, Component } from "vue-property-decorator";
import AddProjectStore from "../../store/AddProject";
import FetchProjects from "../../store/FetchProjects";
import GenerateBNSteps from "../../components/GenerateBNSteps.vue";
import sleep from "sleep-promise";

@Component({
  components: { GenerateBNSteps },
})
export default class AddProject extends Vue {
  inputProjectId = "";
  inputProjectIdFailed = false;
  inputPiId: string | null = null;
  inputPiIdFailed = false;
  inputDiseaseId: string | null = null;
  inputDiseaseIdFailed = false;
  addProjectResult = "";

  inputSelectProjectId: string | null = null;

  async mounted() {
    AddProjectStore.RESET();
    await AddProjectStore.fetchPreGenData();
    await FetchProjects.fetchProjects({ sortBy: "id", orderBy: 1 });
  }

  onProjectIdSelectChange(projectId: string) {
    this.$router.push(`/generateBN/${projectId}`);
  }

  async uiAddProject() {
    this.addProjectResult = "";
    this.inputProjectIdFailed = false;
    this.inputPiIdFailed = false;
    this.inputDiseaseIdFailed = false;

    this.inputProjectId = this.inputProjectId.trim();

    let passValidate = true;
    await sleep(250);

    //validate input
    if (
      this.inputProjectId.length !== 5 ||
      Number.isNaN(parseInt(this.inputProjectId)) ||
      parseInt(this.inputProjectId) < 1
    ) {
      this.inputProjectIdFailed = true;
      passValidate = false;
    }
    if (this.inputPiId === null) {
      this.inputPiIdFailed = true;
      passValidate = false;
    }
    if (this.inputDiseaseId === null) {
      this.inputDiseaseIdFailed = true;
      passValidate = false;
    }
    if (!passValidate) {
      this.addProjectResult = "Please fill all data correctly";
    } else {
      //extract disease data
      const diseaseId = this.inputDiseaseId || "";

      const result = await AddProjectStore.postAddProject({
        projectId: this.inputProjectId,
        piId: this.inputPiId || "",
        diseaseId,
      });
      this.addProjectResult = result.message;
      if (result.status === "success")
        this.$router.push(`/generateBN/${this.inputProjectId}`);
    }
  }

  get pis() {
    return AddProjectStore.pis;
  }

  get diseases() {
    return AddProjectStore.diseases;
  }

  get projects() {
    return FetchProjects.projects;
  }
}
