import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface FPostTakeTube{
  biobankNo: string;
  locationId: string;
}

@Module({ name: 'TakeTubeStore', store, dynamic: true, namespaced: true })
class TakeTubeStore extends VuexModule {
  @Action
  async postTakeTube(payload: FPostTakeTube){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_TAKETUBE
    return axios.post(
      url, {
        ...payload
      }, {
        headers: {
          token: loadToken()
        }
      }
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        return {
          status: 'failure', message: 'Failed to take sample from the box'
        }
      })
  }
}

export default getModule(TakeTubeStore)
