




































import { Vue, Component } from "vue-property-decorator";
import RolesAndPermissionsTableSkeleton from './RolesAndPermissionsTableSkeleton.vue'
import UserRoleStore from '../../store/UserRole'
import AddRoleModal, { RoleModalType } from './AddRoleModal.vue'

@Component({
  components: {RolesAndPermissionsTableSkeleton}
})
export default class RolesAndPermissions extends Vue {

  async mounted() {
    UserRoleStore.fetchRoles()
  }

  openAddRoleModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AddRoleModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        type: 'add' as RoleModalType
      }
    })
  }

  get roles() {
    return UserRoleStore.roles
  }

  get loading() {
    return UserRoleStore.loading
  }
}
