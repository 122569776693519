import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface APostAddUserAccount{
  username: string;
  password: string;
  prename: string;
  firstname: string;
  lastname: string;
  roleId: string;
}

@Module({ name: 'AddUserAccountModule', store, dynamic: true, namespaced: true})
class AddUserAccountStore extends VuexModule {
  @Action
  async postAddUserAccount(payload: APostAddUserAccount){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_USER_ADD
    return axios.post(
      url,
      { ...payload},
      {
        headers: {
          token: loadToken()
        }
      }
    )
      .then(resp => {
        return resp.data
      })
      .catch(err=> {
        if(err.response)
          return err.response.data
        else
          return {status: 'failure', message:'Failed to add user account'}
      })
  }
}
export default getModule(AddUserAccountStore)