import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface Pi {
  name: string;
  _id: string;
}

interface Disease {
  code: string;
  name: string;
  _id: string;
}

interface Project {
  id: string;
  disease: Disease;
  pi: string;
  countBNs: number;
  dateStr: string;
}

export interface MFetchPreGenData {
  pis: Array<Pi>;
  diseases: Array<Disease>;
}

interface APostAddProject {
  projectId: string;
  piId: string;
  diseaseId: string;
}

@Module({ name: 'AddProjectModule', store, dynamic: true, namespaced: true })
class AddProjectStore extends VuexModule {
  pis: Array<Pi> = []
  diseases: Array<Disease> = []
  projects: Array<Project> = []

  @Mutation
  RESET() {
    this.pis = []
    this.diseases = []
    this.projects = []
  }

  @Mutation
  FETCH_PRE_GEN_DATA(payload: MFetchPreGenData) {
    this.pis = payload.pis
    this.diseases = payload.diseases
  }

  @Mutation
  FETCH_PROJECTS(payload: Array<Project>) {
    this.projects = payload
  }

  @Action
  async fetchPreGenData() {
    return axios.get(
      process.env.VUE_APP_API_BASE_URL +
      process.env.VUE_APP_API_PROJECT_GETPREGENDATA,
      {
        headers: {
          token: loadToken()
        }
      }
    )
      .then(resp => {
        this.FETCH_PRE_GEN_DATA({
          pis: resp.data.pis,
          diseases: resp.data.diseases
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  @Action
  async fetchProjects(payload: {sortBy: string; orderBy: number}) {
    return axios.get(
      process.env.VUE_APP_API_BASE_URL +
      process.env.VUE_APP_API_PROJECT_LIST,
      {
        headers: {
          token: loadToken()
        },
        params:{ ... payload}
      }
    )
      .then(resp => {
        this.FETCH_PROJECTS(resp.data.projects)
      })
      .catch(err => console.log(err))
  }

  @Action
  async postAddProject(payload: APostAddProject) {
    const url = process.env.VUE_APP_API_BASE_URL +
      process.env.VUE_APP_API_PROJECT_ADD
    return axios.post(
      url,
      {
        projectId: payload.projectId,
        piId: payload.piId,
        diseaseId: payload.diseaseId,
      }, {
      headers: {
        token: loadToken()
      }
    }
    ).then(resp => {
      return resp.data
    }).catch(err => {
      if (err?.response) {
        return err.response.data
      }
      return { status: 'failure', message: 'Failed to add project' }
    })
  }
}

export default getModule(AddProjectStore)