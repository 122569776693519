import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface Disease {
  code: string;
  name: string;
  _id: string;
}

interface Staff {
  _id: string;
  name: string;
}

export interface Biospecimen {
  _id: string;
  type: string;
  name: string;
}

interface Project {
  id: string;
  disease: Disease;
  pi: string;
  countBNs: number;
  dateStr: string;
}

interface MFetchPreGenData {
  projects: Array<Project>;
  biospecimenTypes: Array<Biospecimen>;
}

interface Location {
  id: string;
  address: string;
  owner: Staff;
  countBNs: number;
}

interface MFetchProjectPreGenData {
  disease: Disease;
  patientNo: number;
}

interface APostGenerateBN {
  projectId: string;
  diseaseId: string;
  diseaseCode: string;
  patientNo: number;
  visitTime: number;
  biospecimenType: string;
  biospecimenTypeId: string;
  tubeNo: number | null;
  tubeNoUpperBound?: number | null;
  admitDate: Date;
  migrate: boolean;
}

interface MPostGenerateBN {
  newGeneratedBN: string;
  newGeneratedTubeLowerBound: number;
  newGeneratedTubeUpperBound: number;
  newGeneratedBNs: Array<string>;
}

@Module({ name: 'GenerateBNModule', store, dynamic: true, namespaced: true })
class GenerateBNStore extends VuexModule {
  projects: Array<Project> = []
  biospecimenTypes: Array<Biospecimen> = []

  fetchedProjectPreGenData = false
  disease: Disease | null = null
  patientNo: number | null = null
  locations: Array<Location> = []

  generatedBN = false
  newGeneratedBN: string | null = null
  newGeneratedTubeLowerBound: number | null = null
  newGeneratedTubeUpperBound: number | null = null
  newGeneratedBNs: Array<string> = []

  @Mutation
  RESET() {
    this.projects = []
    this.biospecimenTypes = []
    this.fetchedProjectPreGenData = false
    this.disease = null
    this.patientNo = null
    this.locations = []
    this.generatedBN = false
    this.newGeneratedBN = null
  }

  @Mutation
  FETCH_PRE_GEN_DATA({ projects, biospecimenTypes }: MFetchPreGenData) {
    this.projects = projects
    this.biospecimenTypes = biospecimenTypes
  }

  @Mutation
  FETCH_PROJECT_PRE_GEN_DATA({
    disease, patientNo
  }: MFetchProjectPreGenData) {
    this.fetchedProjectPreGenData = true
    this.disease = disease
    this.patientNo = patientNo
  }

  @Mutation
  SET_FETCHED_PROJECT_PRE_GEN_DATA(payload: boolean) {
    this.fetchedProjectPreGenData = payload
  }

  @Mutation
  POST_GENERATE_BN(payload: MPostGenerateBN) {
    this.newGeneratedBN = payload.newGeneratedBN
    this.generatedBN = true
    this.newGeneratedTubeLowerBound = payload.newGeneratedTubeLowerBound
    this.newGeneratedTubeUpperBound = payload.newGeneratedTubeUpperBound
    this.newGeneratedBNs = payload.newGeneratedBNs
  }

  @Action
  async fetchPreGenData() {
    return axios.get(
      process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_GETPREGENDATA,
      {
        headers: {
          token: loadToken()
        }
      }).then((resp) => {
        this.FETCH_PRE_GEN_DATA({
          projects: resp.data.projects,
          biospecimenTypes: resp.data.biospecimenTypes
        })
      }).catch(err => {
        console.log(err)
      })
  }

  @Action
  async postGenerateBN(payload: APostGenerateBN) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_GENERATE_BN
    return axios.post(
      url, {
      ...payload
    }, {
      headers: {
        token: loadToken()
      }
    }).then(resp => {
      this.POST_GENERATE_BN({
        ...resp.data
      })
      return resp.data
    }).catch(err => {
      if (err?.response)
        return err.response.data
      return {
        status: 'failure',
        message: 'Failed to generate BN'
      }
    })
  }

  @Action
  async fetchProjectPreGenData(projectId: string) {
    return axios.post(
      process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_GETPROJECTPREGENDATA, {
      projectId
    }, {
      headers: {
        token: loadToken()
      }
    }).then(resp => {
      this.FETCH_PROJECT_PRE_GEN_DATA({
        disease: resp.data.disease,
        patientNo: resp.data.patientNo,
      })
      return resp.data
    }).catch(err => {
      this.SET_FETCHED_PROJECT_PRE_GEN_DATA(false)
      if (err?.response)
        return err.response.data
      return {
        status: 'failure',
        message: 'Failed to get project data'
      }
    })
  }

  @Action
  async undoGeneration({bns}: {bns: string[]}) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BN_UNDO_GENERATION
    return axios.post(
      url, {
        bns
      }, {
        headers: {
          token: loadToken()
        }
      }
    )
      .then(resp => resp.data)
      .catch(err => {
        if (err?.response)
          return err.response.data
        return {
          status: 'failure',
          message: 'Failed to undo sample(s) generation'
        }
      })
  }
}

export default getModule(GenerateBNStore)