import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import {Location} from './FetchLocations'

@Module({ name: 'PrintLocationLabelModule', store, dynamic: true, namespaced: true })
class PrintLocationLabelStore extends VuexModule {
  locsBasket: Location[] = []

  @Mutation
  ADD_TO_BASKET(payload: {loc: Location}) {
    if (!this.locsBasket.find(x => x.id === payload.loc.id)) {
      this.locsBasket = [...this.locsBasket, payload.loc]
    }
  }

  @Mutation
  DELETE_FROM_BASKET(payload: {locId: string}) {
    this.locsBasket = this.locsBasket.filter(x => x.id !== payload.locId)
  }

  @Mutation
  CLEAR_BASKET(){
    this.locsBasket = []
  }

  @Action
  toggleAddingToBasket(payload: {loc: Location}) {
    if (this.locsBasket.find(x => x.id === payload.loc.id)) {
      this.DELETE_FROM_BASKET({locId: payload.loc.id})
    } else {
      this.ADD_TO_BASKET({loc: payload.loc})
    }
  }
}

export default getModule(PrintLocationLabelStore)
