











































































import { Vue, Component } from "vue-property-decorator";
import BNsTableSkeleton from "../BNs/BNsTableSkeleton.vue";
import SearchLocationStore from "../../store/SearchLocation";
import PrintBNStore from '../../store/Basket'

@Component({
  components: { BNsTableSkeleton },
})
export default class SearchBNResult extends Vue {
  searchLocationMessage = "";

  async mounted() {
    const result = await SearchLocationStore.postSearchTube({
      ...this.$route.query,
    });

    if (result.status !== "success")
      this.searchLocationMessage = result.message;
  }

  async addAllTubesToBasket(){
    const countBefore = PrintBNStore.bnsBasket.length
    const result = await PrintBNStore.postSearchTubeShowAll({...this.$route.query})
    if (result.status === 'success'){
        if (countBefore < PrintBNStore.bnsBasket.length)
          this.$buefy.snackbar.open('Added sample(s) to basket')
        else 
          this.$buefy.snackbar.open('Sample(s) are already in basket')
    }
    else if (result.status === 'failure'){
      this.$buefy.snackbar.open({
        message: 'Failed to add sample(s). Please try again later',
        type: 'is-danger',
        actionText: 'Error'
      })
    }
  }

  viewPrintBasket(){
    this.$router.push('/printBNBasket')
  }

  onShowPerPageSelectChange(showPerPage: string) {
    const newParams = { ...this.$route.query, showPerPage, showPage: "1" };
    this.$router.push({ name: "searchBNResult", query: newParams });
  }

  onSort(field: string, order: string) {
    const processedOrder = order === "asc" ? "1" : "-1";
    const newParams = {
      ...this.$route.query,
      sortBy: field,
      orderBy: processedOrder,
    };
    this.$router.push({ name: "searchBNResult", query: newParams });
  }

  get defaultSort() {
    const processedOrder = this.$route.query.orderBy === "1" ? "asc" : "desc";
    return [this.$route.query.sortBy, processedOrder];
  }

  get showPage() {
    const showPage = this.$route.query.showPage;
    if (typeof showPage === "string") return parseInt(showPage);
    else return null;
  }

  set showPage(showPage: number | null) {
    const newParams = {
      ...this.$route.query,
      ...{ showPage: String(showPage) },
    };
    this.$router.push({ name: "searchBNResult", query: newParams });
  }

  get showPerPage() {
    const showPerPage = this.$route.query.showPerPage;
    if (typeof showPerPage === "string") return parseInt(showPerPage);
    else return null;
  }

  get showFrom() {
    if (this.showPage && this.showPerPage)
      if (this.showTo) return (this.showPage - 1) * this.showPerPage + 1;
      else return 0;
    else return 0;
  }

  get showTo() {
    if (this.showPage && this.showPerPage)
      return (this.showPage - 1) * this.showPerPage + this.searchedBNs.length;
    else return 0;
  }

  get searchedBNs() {
    const bnsInBasket: Record<string, boolean> = {}
    for(const bn of PrintBNStore.bnsBasket) {
      bnsInBasket[bn.biobankNo] = true
    }

    return SearchLocationStore.searchedBNs.map((x, i) => {
      if (this.showPage && this.showPerPage)
        return {
          ...x,
          index: i + 1 + (this.showPage - 1) * this.showPerPage,
          isInBasket: bnsInBasket[x.biobankNo] ? true : false
        };
    });
  }

  get searchedBNCount() {
    return SearchLocationStore.bnCount;
  }

  get searchedTube() {
    return SearchLocationStore.searchedTube;
  }

  get showColumnOptions() {
    return SearchLocationStore.showColumnOptions;
  }

  get loadingPostSearchTube() {
    return SearchLocationStore.loadingPostSearchTube
  }

  get basketLength(){
    return PrintBNStore.bnsBasket.length
  }
}
