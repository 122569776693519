



































































import { Vue, Component, Prop } from "vue-property-decorator";
import { format } from "date-fns";
import UpdateUserAccountStore from "../../store/UpdateUserAccount";
import AuthenticateStore, { loadToken } from "../../store/Authenticate";
import sleep from "sleep-promise";
import UserRoleStore from '../../store/UserRole'
import {computeRoleOptionText} from '../../utils/Role'

@Component
export default class EditUserAccountModal extends Vue {
  @Prop() readonly username: string | undefined;
  @Prop() readonly prename: string | undefined;
  @Prop() readonly firstname: string | undefined;
  @Prop() readonly lastname: string | undefined;
  @Prop() readonly roleId: string | undefined;
  @Prop() readonly createdAt: string | undefined;
  @Prop() readonly onClose: Function | undefined;

  updateResult = "";
  isChangePassword = false;

  state: {
    username: string;
    prename: string;
    firstname: string;
    lastname: string;
    roleId: string;
    createdAt: string | null;
    newPassword: string;
    confirmNewPassword: string;
  } = {
    username: "",
    prename: "",
    firstname: "",
    lastname: "",
    roleId: '',
    createdAt: null,
    newPassword: "",
    confirmNewPassword: "",
  };

  computeRoleOptionText=computeRoleOptionText

  mounted() {
    if (this.username) this.state.username = this.username;
    if (this.prename) this.state.prename = this.prename;
    if (this.firstname) this.state.firstname = this.firstname;
    if (this.lastname) this.state.lastname = this.lastname;
    if (this.roleId) this.state.roleId = this.roleId
    if (this.createdAt)
      this.state.createdAt =
        this.formatDate(this.createdAt) + " " + this.formatTime(this.createdAt);
    UserRoleStore.fetchRoles()
  }

  get bindUsername() {
    return this.username || "";
  }
  set bindUsername(v: string) {
    this.state.username = v;
  }
  get bindPrename() {
    return this.prename || "";
  }
  set bindPrename(v: string) {
    this.state.prename = v;
  }
  get bindFirstname() {
    return this.firstname || "";
  }
  set bindFirstname(v: string) {
    this.state.firstname = v;
  }
  get bindLastname() {
    return this.lastname || "";
  }
  set bindLastname(v: string) {
    this.state.lastname = v;
  }
  get bindIsChangePassword() {
    return this.isChangePassword;
  }
  set bindIsChangePassword(v: boolean) {
    this.isChangePassword = v;
    if (v === false) {
      this.state.newPassword = "";
      this.state.confirmNewPassword = "";
    }
  }

  formatDate(dateStr: string) {
    return format(new Date(dateStr), "yyyy-MM-dd");
  }

  formatTime(dateStr: string) {
    return format(new Date(dateStr), "HH:mm:ss");
  }

  async updateUserAccount() {
    this.updateResult = "";
    await sleep(250);

    this.state.prename = this.state.prename.trim();
    this.state.firstname = this.state.firstname.trim();
    this.state.lastname = this.state.lastname.trim();
    this.state.newPassword = this.state.newPassword.trim();
    this.state.confirmNewPassword = this.state.confirmNewPassword.trim();

    //validate
    if (!this.state.firstname || !this.state.lastname) {
      this.updateResult = "Firstname and lastname must not be empty";
      return;
    }

    if (
      this.isChangePassword &&
      this.state.newPassword !== this.state.confirmNewPassword
    ) {
      this.updateResult = "Password confirmation does not match";
      return;
    }

    const result = await UpdateUserAccountStore.updateUserAccount({
      username: this.state.username,
      prename: this.state.prename,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      roleId: this.state.roleId,
      newPassword: this.isChangePassword ? this.state.newPassword : undefined,
      confirmNewPassword: this.isChangePassword
        ? this.state.confirmNewPassword
        : undefined,
    });

    if (result.status === "success") {
      //relog in to refresh user info of navbar
      AuthenticateStore.reSignIn(loadToken() || null);
      //show update result
      this.$emit("close");
      this.$router
        .push({
          name: "userAccountsUpdated",
          params: { updatedUsername: this.state.username },
        })
        .catch(() => {
          this.$router.go(0);
        });
    } else {
      this.updateResult = result.message;
    }
  }

  openConfirmDelete() {
    this.$buefy.dialog.confirm({
      title: "Deleting User Account",
      message:
        "Are you sure you want to <b>delete</b> account? This action cannot be undone.",
      confirmText: "Delete",
      type: "is-danger",
      hasIcon: true,
      onConfirm: () => this.deleteUserAccount(),
    });
  }

  async deleteUserAccount() {
    this.updateResult = "";
    sleep(250);
    const result = await UpdateUserAccountStore.deleteUserAccount({
      username: this.username || "",
    });

    if (result.status === "success") {
      this.$emit("close");
      this.$router
        .push({
          name: "userAccountsDeleted",
        })
        .catch(() => {
          this.$router.go(0);
        });
    } else if (result.status === "failure") {
      this.updateResult = result.message;
    }
  }

  get loadingRoles() {
    return UserRoleStore.loading;
  }

  get roles() {
    return UserRoleStore.roles;
  }
}
