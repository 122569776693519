import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface AUpdateDisease{
  code: string;
  name: string;
}

interface ADeleteDisease{
  code: string;
}

@Module({ name: 'UpdateDiseaseModule', store, dynamic: true, namespaced: true })
class UpdateDiseaseStore extends VuexModule {
  @Action updateDisease(payload: AUpdateDisease){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_DISEASE_UPDATE
    return axios.put(
      url, {...payload}, { headers: {token: loadToken()}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to update disease. Please try again later.'
          }
      })
  }

  @Action deleteDisease(payload: ADeleteDisease){
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_DISEASE_DELETE
    return axios.delete(
      url, { headers: {token: loadToken()}, params: {...payload}}
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data
        else
          return {
            status: 'failure',
            message: 'Failed to delete disease'
          }
      })
  }
}

export default getModule(UpdateDiseaseStore)