













































































import { Vue, Component } from "vue-property-decorator";
import AddUserAccountStore from '../../store/AddUserAccount'
import UserRoleStore from '../../store/UserRole'
import sleep from 'sleep-promise'
import {computeRoleOptionText} from '../../utils/Role'

function validateEmail(email: string) {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(String(email).toLowerCase());
}

@Component
export default class AddUserAccount extends Vue {
  inputUsername = ''
  inputUsernameFailed = false
  inputPassword = ''
  inputPasswordFailed = false
  inputConfirmPassword = ''
  inputConfirmPasswordFailed = false
  inputPrename = ''
  inputPrenameFailed = false
  inputFirstname = ''
  inputFirstnameFailed = false
  inputLastname = ''
  inputLastnameFailed = false
  inputRoleId: string | null = null
  inputRoleIdFailed = false
  postResult = ''
  
  computeRoleOptionText=computeRoleOptionText

  async mounted() {
    UserRoleStore.fetchRoles()
  }

  async uiPostAddUserAccount(){
    this.postResult = ''
    this.inputUsernameFailed = false
    this.inputPasswordFailed = false
    this.inputConfirmPasswordFailed = false
    this.inputPrenameFailed = false
    this.inputFirstnameFailed = false
    this.inputLastnameFailed = false
    this.inputRoleIdFailed = false
    let passValidate = true
    await sleep(250)

    //trim
    this.inputUsername = this.inputUsername.trim()
    this.inputPassword = this.inputPassword.trim()
    this.inputConfirmPassword = this.inputConfirmPassword.trim()
    this.inputPrename = this.inputPrename.trim()
    this.inputFirstname = this.inputFirstname.trim()
    this.inputLastname = this.inputLastname.trim()

    //validate
    if(!this.inputUsername || !validateEmail(this.inputUsername)){
      passValidate = false
      this.inputUsernameFailed = true
    }
    if(
      this.inputPassword.length < 6
    ){
      passValidate = false
      this.inputPasswordFailed = true
    }
    if(this.inputPassword !== this.inputConfirmPassword){
      passValidate = false
      this.inputConfirmPasswordFailed = true
    }
    if(!this.inputFirstname){
      passValidate = false
      this.inputFirstnameFailed = true 
    }
    if(!this.inputLastname){
      passValidate = false
      this.inputLastnameFailed = true
    }
    if(!this.inputRoleId){
      passValidate = false
      this.inputRoleIdFailed = true
    }
    if(!passValidate){
      this.postResult = 'Please fill all required data correctly'
      return
    }

    const result = await AddUserAccountStore.postAddUserAccount({
      username: this.inputUsername,
      password: this.inputPassword,
      prename: this.inputPrename,
      firstname: this.inputFirstname,
      lastname: this.inputLastname,
      roleId: this.inputRoleId || ''
    })

    if(result.status === 'success'){
      this.$router.push({name:'addUserAccount', query:{addUserAccountSuccess:'true', username:result.username}})
    }
    else if(result.status === 'failure'){
      this.postResult = result.message
    }
  }

  get loadingRoles() {
    return UserRoleStore.loading
  }

  get roles() {
    return UserRoleStore.roles
  }

}
