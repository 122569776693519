import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './index'
import axios from '../utils/CustomAxios'
import { loadToken } from './Authenticate'

interface APostMoveTubes {
  biobankNos: Array<string>;
  locationId: string;
}

@Module({ name: 'MoveTubesStore', store, dynamic: true, namespaced: true })
class MoveTubesStore extends VuexModule {
  @Action
  async postMoveTubes(payload: APostMoveTubes) {
    const url = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_LOCATION_MOVETUBES
    return axios.post(
      url, { ...payload },
      { headers: { token: loadToken() } }
    )
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        if (err.response)
          return err.response.data

        return {
          status: 'failure', message: 'Failed to move sample(s)'
        }
      })
  }
}

export default getModule(MoveTubesStore)