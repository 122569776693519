

































































































































import { Vue, Component } from "vue-property-decorator";
import AuthenticateStore from "../store/Authenticate";

@Component
export default class Navbar extends Vue {
  showMobileNavbar = false;
  route(path: string) {
    this.$router.push(path).catch(() => {
      this.$router.go(0);
    });
  }

  uiToggleMobileNavbar() {
    this.showMobileNavbar = !this.showMobileNavbar;
  }

  navigateAndCloseHam(url: string) {
    this.route(url);
    this.showMobileNavbar = false;
  }

  uiSignOut() {
    AuthenticateStore.signOut();
    this.route("/");
  }

  get signedIn() {
    return AuthenticateStore.signedIn;
  }

  get signedInName() {
    if (AuthenticateStore.signedInPrename)
      return `${AuthenticateStore.signedInPrename} ${AuthenticateStore.signedInFirstname} ${AuthenticateStore.signedInLastname}`;
    else
      return `${AuthenticateStore.signedInFirstname} ${AuthenticateStore.signedInLastname}`;
  }

  get hasUserAndRolePms() {
    if (AuthenticateStore.signedInPms) {
      return AuthenticateStore.signedInPms.userAndRole;
    } else return false;
  }

  get hasAddPms() {
    if (AuthenticateStore.signedInPms) {
      return AuthenticateStore.signedInPms.add;
    } else return false;
  }
}
