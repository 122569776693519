






























































import sleep from "sleep-promise";
import { Vue, Component, Prop } from "vue-property-decorator";
import UpdateLocationStore from "../../store/UpdateLocation";
import {authenedUserHasPms} from '../../utils/Role'

@Component
export default class EditLocationModal extends Vue {
  @Prop() readonly onClose: Function | undefined;
  @Prop() readonly boxId: string | undefined; //always refer to original boxId
  @Prop() readonly building: string | undefined;
  @Prop() readonly room: string | undefined;
  @Prop() readonly label: string | undefined;

  inputBoxId = "";
  inputBuilding = "";
  inputBuildingFailed = false;
  inputRoom = "";
  inputRoomFailed = false;
  inputLabel = "";
  updateResult = "";

  authenedUserHasPms = authenedUserHasPms

  mounted() {
    this.inputBoxId = this.boxId || "";
    this.inputBuilding = this.building || "";
    this.inputRoom = this.room || "";
    this.inputLabel = this.label || "";
  }

  async updateBoxLocation() {
    this.updateResult = "";
    this.inputBuildingFailed = false;
    this.inputRoomFailed = false;
    let passValidate = true;
    await sleep(250);

    this.inputBoxId = this.inputBoxId.trim();
    this.inputBuilding = this.inputBuilding.trim();
    this.inputRoom = this.inputRoom.trim();
    this.inputLabel = this.inputLabel.trim();

    //validate
    if (!this.inputBuilding) {
      this.inputBuildingFailed = true;
      passValidate = false;
    }
    if (!this.inputRoom) {
      this.inputRoomFailed = true;
      passValidate = false;
    }
    if (!passValidate) {
      this.updateResult = "Please fill all required data correctly";
      return;
    }

    const result = await UpdateLocationStore.updateLocation({
      id: this.boxId || "",
      building: this.inputBuilding,
      room: this.inputRoom,
      label: this.inputLabel,
    });
    if (result.status === "failure") this.updateResult = result.message;
    else if (result.status === "success"){
      this.$emit("close")
      this.$router
        .push({
          name: "locationsUpdated",
          params: { updatedLocation: this.inputBoxId },
        })
        .catch(() => this.$router.go(0));
    }
  }

  openConfirmDelete() {
    this.$buefy.dialog.confirm({
      title: "Deleting Box Location",
      message:
        "Are you sure you want to <b>delete</b> box location? This action cannot be undone.",
      confirmText: "Delete",
      type: "is-danger",
      hasIcon: true,
      onConfirm: () => this.deleteBoxLocation(),
    });
  }

  async deleteBoxLocation() {
    this.updateResult = "";
    sleep(250);
    const result = await UpdateLocationStore.deleteLocation({
      id: this.boxId || "",
    });

    if (result.status === "success") {
      this.$emit('close')
      this.$router
        .push({
          name: "locationsDeleted",
        })
        .catch(() => this.$router.go(0));
    } else if (result.status === "failure") {
      this.updateResult = result.message;
    }
  }
}
